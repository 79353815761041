import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";
import { useStore } from "../flow/store";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import Prior from "./Prior";
import NoContext from "../../components/noSubscription/NoContext";
import NoContextSelected from "../../components/noSubscription/NoContextSelected";

const Route = () => {
  const [page, setPage] = useState("Prior");

  const contexts = useStore((state) => state.contexts);
  const contextId = useStore((state) => state.contextId);
  const priorYearss = usePriorYearsStore((state) => state.priorYears);
  const priorYears = priorYearss.find((priorYear) => priorYear.contextId === contextId);

  if (contexts.length === 0) {
    return <NoContext />;
  }
  if (!contextId) {
    return <NoContextSelected />;
  }

  return (
    <div className="flex-grow overflow-y-auto p-4 bg-white rounded-3xl">
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "Prior" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("Prior")}
        >
          Prior
        </button>
      </div>

      {page === "Prior" && priorYears && <Prior priorYears={priorYears} />}
    </div>
  );
};

export default Route;
