import { useStore } from "../flow/store";
import { useAssumptionsStore } from "../../stores/useAssumptionsStore";
import { useStatementsAnnualStore } from "../../stores/useStatementsAnnualStore";
import Dashboard from "./Dashboard";
import NoContext from "../../components/noSubscription/NoContext";
import NoContextSelected from "../../components/noSubscription/NoContextSelected";

const Route = () => {
  const contexts = useStore((state) => state.contexts);
  const contextId = useStore((state) => state.contextId);
  const assumptions = useAssumptionsStore((state) => state.assumptions);
  const statementsAnnual = useStatementsAnnualStore((state) => state.statementsAnnual);

  const assumption = assumptions.find((item) => item.contextId === contextId);
  const annual = statementsAnnual.find((item) => item.contextId === contextId);

  if (contexts.length === 0) {
    return <NoContext />;
  }
  if (!contextId) {
    return <NoContextSelected />;
  }

  return (
    <div className="flex-grow overflow-y-auto">
      <Dashboard annual={annual} assumption={assumption} />
    </div>
  );
};

export default Route;
