import SummaryOverview from "./charts/SummaryOverview";
import ExpenseBreakdown from "./charts/ExpensesBreakdown";
import RevenueProjection from "./charts/RevenueProjection";
import FundingNeedsChart from "./charts/FundingNeeds";
import CashflowForecast from "./charts/CashFlowChart";
import FundingSourcesChart from "./charts/FundingSources";

const Dashboard = ({ annual, assumption }) => {
  const { monthsNamesAnnual } = assumption;

  return (
    <div className="grid grid-cols-7 gap-6">
      <div className="bg-white rounded-lg shadow-md col-span-5 p-4">
        <SummaryOverview annual={annual} xaxisLabels={monthsNamesAnnual} />
      </div>
      <div className="bg-white rounded-lg shadow-lg col-span-2 p-4">
        <ExpenseBreakdown annual={annual} xaxisLabels={monthsNamesAnnual} />
      </div>
      <div className="bg-white rounded-lg shadow-lg col-span-4 p-4">
        <RevenueProjection annual={annual} xaxisLabels={monthsNamesAnnual} />
      </div>
      <div className="bg-white rounded-lg shadow-lg col-span-3 p-4">
        <FundingNeedsChart />
      </div>
      <div className="bg-white rounded-lg shadow-lg col-span-3 p-4">
        <CashflowForecast annual={annual} xaxisLabels={monthsNamesAnnual} />
      </div>
      <div className="bg-white rounded-lg shadow-lg col-span-4 p-4">
        <FundingSourcesChart />
      </div>
    </div>
  );
};

export default Dashboard;
