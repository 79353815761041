import { nonEditable, showZero, textCell, numberCell } from "../../statements/reactgrid/cells";

import {
  ROW_HEIGHT_28,
  // ROW_HEIGHT_34,
  // ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../../statements/reactgrid/getRows";

import {
  getBlankRow,
  getHeaderRow,
  getSubheaderRow,
  getSubSubheaderRow,
  getSubtotalRow,
  getTotalRow,
  getCheckRow,
} from "../../statements/reactgrid/getRows";

const getContentRow = ({ assumption, category, description, longs }) => {
  const { priorLength } = assumption;
  const className1 = "text-md font-weight-400 padding-left-2";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.description, className1)),
      ...long.long.map((value, index) =>
        index < priorLength
          ? nonEditable(showZero(numberCell(value, className1)))
          : showZero(numberCell(value, className1))
      ),
    ],
  }));

  return rows;
};

export const getRowsSOFP = ({ assumption, actual }) => {
  const { totalMonths } = assumption;
  const months = totalMonths;

  const longProperties = actual.sofp.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    // assetsLongs,
    equityLongs,
    // liabilitiesLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, assumption })];

  // ASSETS
  rows = [...rows, ...getSubheaderRow({ months, title: "ASSETS" })];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Non-current assets" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "nonCurrentAssetsLongs",
      longs: nonCurrentAssetsLongs,
    }),
    ...getSubtotalRow({ title: "Non-current assets", long: totalNonCurrentAssetsLong }),
    ...getBlankRow({ months }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Current assets" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "currentAssetsLongs",
      longs: currentAssetsLongs,
    }),
    ...getSubtotalRow({ title: "Current assets", long: totalCurrentAssetsLong }),
  ];
  rows = [...rows, ...getTotalRow({ title: "Total Assets", long: totalAssetsLong }), ...getBlankRow({ months })];

  // EQUITY
  rows = [
    ...rows,
    ...getSubheaderRow({ months, title: "EQUITY" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "equityLongs",
      longs: equityLongs,
    }),
    ...getTotalRow({ title: "Total Equity", long: totalEquityLong }),
    ...getBlankRow({ months }),
  ];

  // LIABILITIES
  rows = [...rows, ...getSubheaderRow({ months, title: "LIABILITIES" })];

  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Non-current liabilities" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "nonCurrentLiabilitiesLongs",
      longs: nonCurrentLiabilitiesLongs,
    }),
    ...getSubtotalRow({ title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong }),
    ...getBlankRow({ months }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Current liabilities" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "currentLiabilitiesLongs",
      longs: currentLiabilitiesLongs,
    }),
    ...getSubtotalRow({ title: "Current liabilities", long: totalCurrentLiabilitiesLong }),
  ];
  rows = [
    ...rows,
    ...getTotalRow({ title: "Total Liabilities", long: totalLiabilitiesLong }),
    ...getBlankRow({ months }),
  ];

  // OVERALL
  rows = [...rows, ...getCheckRow({ title: "Check", long: checkLong }), ...getBlankRow({ months })];

  return rows;
};

export const getRowsSOPL = ({ assumption, actual }) => {
  const { totalMonths } = assumption;
  const months = totalMonths;

  const longProperties = actual.sopl.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    // totalRevenueLong,
    // totalExpensesLong,
    // totalIntExpLong,
    // totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, assumption })];

  rows = [
    ...rows,
    ...getContentRow({ assumption, category: "sopl", description: "revenueLongs", longs: revenueLongs }),
  ];
  rows = [
    ...rows,
    ...getContentRow({ assumption, category: "sopl", description: "expensesLongs", longs: expensesLongs }),
  ];
  rows = [...rows, ...getSubtotalRow({ title: "PBIT", long: totalPbitLong })];
  rows = [...rows, ...getContentRow({ assumption, category: "sopl", description: "intExpLongs", longs: intExpLongs })];
  rows = [...rows, ...getSubtotalRow({ title: "PBT", long: totalPbtLong })];
  rows = [...rows, ...getContentRow({ assumption, category: "sopl", description: "taxLongs", longs: taxLongs })];
  rows = [...rows, ...getTotalRow({ title: "Profit/(Loss)", long: totalProfitLong }), ...getBlankRow({ months })];

  return rows;
};

export const getRowsSOCF = ({ assumption, actual }) => {
  const { totalMonths } = assumption;
  const months = totalMonths;

  const longProperties = actual.socf.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, assumption })];

  // OPERATING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from operating activities" }),
    ...getContentRow({
      assumption,
      category: "socf",
      description: "operatingLongs",
      longs: operatingLongs,
    }),
    ...getSubtotalRow({ title: "Cash flow from operating activities", long: totalOperatingLong }),
    ...getBlankRow({ months }),
  ];

  // INVESTING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from investing activities" }),
    ...getContentRow({
      assumption,
      category: "socf",
      description: "investingLongs",
      longs: investingLongs,
    }),
    ...getSubtotalRow({ title: "Cash flow from investing activities", long: totalInvestingLong }),
    ...getBlankRow({ months }),
  ];

  // FINANCING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from financing activities" }),
    ...getContentRow({
      assumption,
      category: "socf",
      description: "financingLongs",
      longs: financingLongs,
    }),
    ...getSubtotalRow({ title: "Cash flow from financing activities", long: totalFinancingLong }),
    ...getBlankRow({ months }),
  ];

  rows = [...rows, ...getTotalRow({ title: "Total cash flow movement", long: totalLong }), ...getBlankRow({ months })];

  return rows;
};
