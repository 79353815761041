import FlowCSS from "../../../../styles/pages/Flow.module.css";
import { useStore } from "../../store";
import { useAssumptionsStore } from "../../../../stores/useAssumptionsStore";
import SideCircle from "../../components/SideCircle";
import { InputNode, DisplayOutputs } from "../utils/NodeIO";

export const selector = (id, data) => (store) => ({
  node: store.getNode(id),
  container: store.getContainer(id),
});

const ContNormalNode = (fromReactFlow) => {
  const { id, data } = fromReactFlow;
  const { node, container } = useStore(selector(id, data));
  if (!node) return;
  const assumption = useAssumptionsStore.getState().assumptions.find((item) => item.contextId === node.contextId);
  const { priorLength } = assumption;

  return (
    <div className={FlowCSS.node}>
      <SideCircle container={container} />

      <>
        <div className={FlowCSS.container_header}>
          <h2>{data.general.description}</h2>
        </div>

        <>
          <h3>Inputs</h3>
          <div className={FlowCSS.big}>
            {data.assignments.map((item) => (
              <InputNode key={item.id} name={item.description} value={item.long[priorLength]} handleId={item.id} />
            ))}
          </div>
        </>

        <DisplayOutputs outputs={data.outputs} priorLength={priorLength} />
      </>
    </div>
  );
};

export default ContNormalNode;
