import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { nanoid } from "nanoid";
import { Reorder } from "framer-motion";
import { useState } from "react";
import { ButtonAdd, ButtonEdit, ButtonDelete, ButtonDrag } from "./Button";
import { SelectCustom } from "./Select";
import { Table } from "./Table";
import { ModalEditNumber, ModalEditFormulas } from "./ModalEdit";
import { formatValue, formatDate } from "./Format";
import IconButton from "@mui/material/IconButton";

export const PriorsBox = ({ priors, priorYears }) => {
  const handleDragStart = (e, item) => {
    // priors => assignments / formulas
    const fromPriors = { item: item };

    const info = { fromPriors: fromPriors };
    e.dataTransfer.setData("application/json", JSON.stringify(info));
  };

  return (
    <>
      <div className={RightBarCSS.tableContainer}>
        <table className={RightBarCSS.table}>
          <thead>
            {priorYears.headers.slice(0, -1).map((header, rowIndex) => (
              <tr key={rowIndex}>
                {header.slice(0, -1).map((item, colIndex) => (
                  <th key={colIndex}>
                    {rowIndex === 0 && colIndex > 0 ? <span>{formatDate(item)}</span> : <span>{item}</span>}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {priors.map((prior, rowIndex) => (
              <tr key={rowIndex}>
                {prior.map((item, colIndex) => (
                  <td key={colIndex}>
                    {colIndex === 0 ? (
                      <div className={RightBarCSS.flex} draggable onDragStart={(e) => handleDragStart(e, item)}>
                        <ButtonDrag />
                        <span>{item}</span>
                      </div>
                    ) : (
                      <span>{formatValue(item)}</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const AssignmentsBox = ({
  assignments,
  setAssignsCustom,
  setAssigns,
  addAssign,
  deleteAssign,
  setCustomLong,
  resetCustomLong,
  assumptions,
}) => {
  const { monthsNames, monthsArray } = assumptions;

  const handleDragStart = (e, id) => {
    // assignments => formulas
    const assignmentsFormulas = { id: id };

    const info = { assignmentsFormulas: assignmentsFormulas };
    e.dataTransfer.setData("application/json", JSON.stringify(info));
  };

  const handleDropFromPriors = (e, index) => {
    e.preventDefault();

    const infoString = e.dataTransfer.getData("application/json");
    const info = JSON.parse(infoString);

    // assignments <= priors
    if (info.fromPriors) {
      const bfLink = info.fromPriors.item;
      setAssigns(index, "bfLink", bfLink);
    }
  };

  const [modalState, setModalState] = useState(-1);
  const handleOpenModal = (index) => {
    setModalState(index);
  };
  const handleCloseModal = () => {
    setModalState(-1);
  };

  return (
    <>
      <div className={RightBarCSS.tableContainer}>
        <table className={RightBarCSS.table}>
          <thead>
            <tr>
              <th></th>
              <th>Month</th>
              <th></th>
              {monthsNames.map((month, index) => (
                <th key={index}>{month}</th>
              ))}
            </tr>
            <tr>
              <th>Utils</th>
              <th>Month</th>
              <th>bfLink</th>
              {monthsArray.map((month, index) => (
                <th key={index}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {assignments.map((item, index) => (
              <tr key={index}>
                <td>
                  <>
                    {item.linkCustom === "Link" && <ButtonEdit disabled />}
                    {item.linkCustom === "Custom" && (
                      <>
                        <ButtonEdit onClick={() => handleOpenModal(index)} />
                        {modalState === index && (
                          <ModalEditNumber
                            open={true}
                            handleClose={handleCloseModal}
                            item={item}
                            index={index}
                            setOutputs={setAssignsCustom}
                            setCustomLong={setCustomLong}
                            resetCustomLong={resetCustomLong}
                            assumptions={assumptions}
                          />
                        )}
                      </>
                    )}
                    {item.type === "Default" && <ButtonDelete disabled />}
                    {item.type === "Custom" && <ButtonDelete onClick={() => deleteAssign(index)} />}
                  </>
                </td>
                <td>
                  <div className={RightBarCSS.flex} draggable onDragStart={(e) => handleDragStart(e, item.id)}>
                    <ButtonDrag />
                    <input
                      type="text"
                      name="text"
                      className={RightBarCSS.inputText}
                      value={item.description}
                      onChange={(e) => {
                        if (item.type === "Custom") {
                          setAssigns(index, "description", e.target.value);
                        }
                      }}
                      disabled={item.type === "Default"}
                    />
                  </div>
                </td>
                <td>
                  {item.linkCustom === "Custom" && (
                    <div className={RightBarCSS.bfLink} onDrop={(e) => handleDropFromPriors(e, index)}>
                      {item.bfLink !== null && (
                        <span className={RightBarCSS.closeIcon} onClick={() => setAssigns(index, "bfLink", null)}>
                          &#x2716;
                        </span>
                      )}
                      {item.bfLink}
                    </div>
                  )}
                  {item.linkCustom === "Link" && (
                    <div className={RightBarCSS.bfLink}>
                      <span className={RightBarCSS.closeAlwaysIcon}>&#x2716;</span>
                      {item.bfLink}
                    </div>
                  )}
                </td>
                {item.long.map((value, colIndex) => (
                  <td key={colIndex}>{formatValue(value)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ButtonAdd onClick={addAssign} />
    </>
  );
};

export const FormulasBox = ({
  formulas,
  setFormulas,
  addFormula,
  deleteFormula,
  addFormulasParam,
  deleteFormulasParam,
  sortParamsIndex,
}) => {
  const handleDrop = (e, index) => {
    e.preventDefault();

    const infoString = e.dataTransfer.getData("application/json");
    const info = JSON.parse(infoString);

    // formulas <= assignments
    if (info.assignmentsFormulas) {
      const toAdd = {
        id: nanoid(),
        linkType: "linkAssign",
        link: info.assignmentsFormulas.id,
      };
      addFormulasParam(index, toAdd);
    }
    // formulas <= operators
    if (info.operatorsFormulas) {
      const toAdd = {
        id: nanoid(),
        linkType: info.operatorsFormulas.linkType,
        description: info.operatorsFormulas.description,
      };
      addFormulasParam(index, toAdd);
    }
    // formulas <= formulas
    if (info.formulasFormulas) {
      const toAdd = {
        id: nanoid(),
        linkType: "linkFormula",
        link: info.formulasFormulas.id,
      };
      addFormulasParam(index, toAdd);
    }
  };
  const handleDropFromPriors = (e, index) => {
    e.preventDefault();

    const infoString = e.dataTransfer.getData("application/json");
    const info = JSON.parse(infoString);

    // formulas <= priors
    if (info.fromPriors) {
      const bfLink = info.fromPriors.item;
      setFormulas(index, "bfLink", bfLink);
    }
  };

  const handleDragStart = (e, id) => {
    // formulas => formulas
    const formulasFormulas = { id: id };

    const info = { formulasFormulas: formulasFormulas };
    e.dataTransfer.setData("application/json", JSON.stringify(info));
  };

  const [modalState, setModalState] = useState(-1);
  const handleOpenModal = (index) => {
    setModalState(index);
  };
  const handleCloseModal = () => {
    setModalState(-1);
  };

  return (
    <>
      <Operators />
      <div className={RightBarCSS.formulasBox}>
        {formulas.map((item, index) => (
          <div key={index} className={RightBarCSS.formulas_line}>
            <div className={RightBarCSS.hello}>
              <ButtonEdit onClick={() => handleOpenModal(index)} />
              {modalState === index && (
                <ModalEditFormulas
                  open={true}
                  handleClose={handleCloseModal}
                  item={item}
                  index={index}
                  setOutputs={setFormulas}
                />
              )}
              {item.type === "Default" && <ButtonDelete disabled />}
              {item.type === "Custom" && <ButtonDelete onClick={() => deleteFormula(index)} />}
            </div>

            <div className={RightBarCSS.hello}>
              {item.type === "Default" && (
                <SelectCustom item={item} keyQues={"calcType"} choices={["Basic", "B/F", "C/F"]} disabled />
              )}
              {item.type === "Custom" && (
                <SelectCustom
                  item={item}
                  keyQues={"calcType"}
                  onChange={(e) => {
                    setFormulas(index, "calcType", e.target.value);
                  }}
                  choices={["Basic", "B/F", "C/F"]}
                />
              )}
            </div>

            <div className={RightBarCSS.hello}>
              <div className={RightBarCSS.input_box} onDrop={(e) => handleDropFromPriors(e, index)}>
                <span className={RightBarCSS.input_item}>
                  {item.bfLink !== null && (
                    <span className={RightBarCSS.closeIcon} onClick={() => setFormulas(index, "bfLink", null)}>
                      &#x2716;
                    </span>
                  )}
                  {item.bfLink}
                </span>
              </div>
            </div>

            <div className={RightBarCSS.hello} draggable onDragStart={(e) => handleDragStart(e, item.id)}>
              <ButtonDrag />
              <input
                type="text"
                name="text"
                className={RightBarCSS.inputTextLong}
                value={item.description}
                readOnly={item.type === "Default"}
                onChange={(e) => {
                  if (item.type === "Custom") {
                    setFormulas(index, "description", e.target.value);
                  }
                }}
              />
            </div>

            <div className={RightBarCSS.hello}>
              <span className={RightBarCSS.operators_item}>=</span>
              {item.type === "Default" && (
                <div className={RightBarCSS.formulas_box}>
                  {item.params.map((param, paramIndex) => (
                    <span key={param.id} className={RightBarCSS.formulas_items}>
                      {param.description}
                    </span>
                  ))}
                </div>
              )}
              {item.type === "Custom" && (
                <div className={RightBarCSS.formulas_box} onDrop={(e) => handleDrop(e, index)}>
                  <Reorder.Group
                    axis="x"
                    values={item.params.map((param) => param.id)}
                    onReorder={(sortedIds) => sortParamsIndex(sortedIds, index)}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {item.params.map((param, paramIndex) => (
                      <Reorder.Item key={param.id} value={param.id}>
                        <span className={RightBarCSS.formulas_items}>
                          <span
                            className={RightBarCSS.closeIcon}
                            onClick={() => deleteFormulasParam(index, paramIndex)}
                          >
                            &#x2716;
                          </span>
                          {param.description}
                        </span>
                      </Reorder.Item>
                    ))}
                  </Reorder.Group>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <ButtonAdd onClick={addFormula} />
    </>
  );
};

export const OutputsBoxNormal = ({ outputs, assumptions }) => {
  return (
    <>
      <Table items={outputs} assumptions={assumptions} />
    </>
  );
};

const Operators = () => {
  const handleDragStart = (e, linkType, description) => {
    // operators => formulas
    const operatorsFormulas = { linkType: linkType, description: description };

    const info = { operatorsFormulas: operatorsFormulas };
    e.dataTransfer.setData("application/json", JSON.stringify(info));
  };

  const style = { height: "2rem", minWidth: "2rem", fontSize: "inherit" };

  return (
    <div className={RightBarCSS.flex}>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "+")}>
        &#x2B;
      </IconButton>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "-")}>
        &#x2212;
      </IconButton>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "*")}>
        &#x2715;
      </IconButton>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "/")}>
        &#x00F7;
      </IconButton>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "(")}>
        &#x28;
      </IconButton>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "operator", ")")}>
        &#x29;
      </IconButton>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "last12MonthsOf")}>
        last12MonthsOf
      </IconButton>
      <IconButton sx={style} draggable onDragStart={(e) => handleDragStart(e, "constant", "100")}>
        100
      </IconButton>
    </div>
  );
};
