const Barchart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 32 32">
      <rect width="32" height="32" fill="none" />
      <path fill="#e68f3d" d="M21.25 8.375V28h6.5V8.375zM12.25 28h6.5V4.125h-6.5zm-9 0h6.5V12.625h-6.5z" />
    </svg>
  );
};

export default Barchart;
