import SelectContext from "../../../../components/selectContext/SelectContext";
import HeaderExport from "./HeaderExport";
import Save from "../../../../components/saveProgress/Save";

const Header = () => {
  return (
    <div
      //
      style={{ height: "var(--header)" }}
      className="shrink-0 flex justify-between items-center"
    >
      <SelectContext />
      <HeaderExport />
      <Save />
    </div>
  );
};

export default Header;
