import StatementsCSS from "../../styles/pages/Statements.module.css";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./reactgrid/styles.css";
import { getColumns } from "./reactgrid/getColumns";
import { getRowsSOCF } from "./reactgrid/getRows";

const SOCF = ({ monthly, assumption, statement, annual }) => {
  if (statement.socf.length === 0) {
    return <div>SOCF node is not present in the context.</div>;
  }

  const columns = getColumns({ monthly, assumption });
  let rows = [];
  if (monthly) {
    rows = getRowsSOCF({ monthly, assumption, statement });
  } else {
    rows = getRowsSOCF({ monthly, assumption, statement: annual });
  }

  return (
    <div className={StatementsCSS.tableContainer}>
      <h1>Statement of Cash Flows</h1>
      <div className="outer">
        <div className="reactgrid">
          <ReactGrid rows={rows} columns={columns} stickyTopRows={3} stickyLeftColumns={1} enableRangeSelection />
        </div>
      </div>
    </div>
  );
};

export default SOCF;
