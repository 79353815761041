import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { formatValue } from "../utils/Format";
import { InputNumberTable, InputNumberTableStaff } from "./InputNumber";

export const Table = ({ items, assumptions }) => {
  const { monthsNames, monthsArray } = assumptions;

  return (
    <div className={RightBarCSS.tableContainer}>
      <table className={RightBarCSS.table}>
        <thead>
          <tr>
            <th>Month</th>
            {monthsNames.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArray.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.description}</td>
              {item.long.map((value, colIndex) => (
                <td key={colIndex}>{formatValue(value)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const TableSingle = ({ item, assumptions }) => {
  const { monthsNames, monthsArray } = assumptions;

  return (
    <div className={RightBarCSS.tableContainer}>
      <table className={RightBarCSS.table}>
        <thead>
          <tr>
            <th>Month</th>
            {monthsNames.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArray.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{item.description}</td>
            {item.long.map((value, colIndex) => (
              <td key={colIndex}>{formatValue(value)}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const TableEdit = ({ item, index, setCustomLong, assumptions }) => {
  const { monthsNames, monthsArray } = assumptions;

  return (
    <div className={RightBarCSS.tableContainer}>
      <table className={RightBarCSS.table}>
        <thead>
          <tr>
            <th>Month</th>
            {monthsNames.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArray.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            {item.long.map((value, colIndex) => (
              <td key={colIndex}>
                {colIndex < assumptions.priorLength ? (
                  <span>{value}</span>
                ) : (
                  <InputNumberTable
                    value={value}
                    onValueChange={(e) => setCustomLong(index, colIndex, parseFloat(e.target.value))}
                    red={item.longCustom[colIndex] !== null}
                  />
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const TableEditNested = ({ item, depthNext, indicesNext, setCustomLong, assumptions }) => {
  const { monthsNames, monthsArray } = assumptions;

  return (
    <div className={RightBarCSS.tableContainer}>
      <table className={RightBarCSS.table}>
        <thead>
          <tr>
            <th>Month</th>
            {monthsNames.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArray.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            {item.long.map((value, colIndex) => (
              <td key={colIndex}>
                {colIndex < assumptions.priorLength ? (
                  <span>{value}</span>
                ) : (
                  <InputNumberTable
                    value={value}
                    onValueChange={(e) => setCustomLong(depthNext, indicesNext, colIndex, parseFloat(e.target.value))}
                    red={item.longCustom[colIndex] !== null}
                  />
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const TableEditStaff = ({ item, index, paramIndex, setCustomLong, assumptions }) => {
  const { monthsNames, monthsArray } = assumptions;

  return (
    <div className={RightBarCSS.tableContainer}>
      <table className={RightBarCSS.table}>
        <thead>
          <tr>
            <th>Month</th>
            {monthsNames.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArray.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            {item.long.map((value, colIndex) => (
              <td key={colIndex}>
                {colIndex < assumptions.priorLength ? (
                  <span>{value}</span>
                ) : (
                  <InputNumberTableStaff
                    value={value}
                    onValueChange={(e) => setCustomLong(index, paramIndex, colIndex, parseInt(e.target.value))}
                    red={item.longCustom[colIndex] !== null}
                  />
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const TableEditCapex = ({ item, index, setCustomLong, assumptions }) => {
  const { monthsNames, monthsArray } = assumptions;

  return (
    <div className={RightBarCSS.tableContainer}>
      <table className={RightBarCSS.table}>
        <thead>
          <tr>
            <th>Month</th>
            {monthsNames.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArray.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            {item.quantityMultiple.map((value, colIndex) => (
              <td key={colIndex}>
                {colIndex < assumptions.priorLength ? (
                  <span>{value}</span>
                ) : (
                  <InputNumberTable
                    value={value}
                    onValueChange={(e) => setCustomLong(index, "quantityMultiple", colIndex, parseInt(e.target.value))}
                  />
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
