import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

import Expand from "./svg/outer/Expand";
import Entrepreneur from "./svg/outer/Entrepreneur";
import Forecast from "./svg/outer/Forecast";
import Financing from "./svg/outer/Financing";

import Dashboard from "./svg/forecast/Dashboard";
import Flow from "./svg/forecast/Flow";
import Statements from "./svg/forecast/Statements";
import Actual from "./svg/forecast/Actual";
import Compare from "./svg/forecast/Compare";
import Prior from "./svg/forecast/Prior";
import Valuation from "./svg/forecast/Valuation";
import Report from "./svg/forecast/Report";
// import Subscription from "./svg/forecast/Subscription";

const styleExpand = "flex items-center h-12 gap-4 rounded-lg";
const styleLink =
  "flex items-center cursor-pointer h-12 gap-4 rounded-lg ml-16 pl-4 transition duration-500 hover:bg-primaryLight group";
const styleLinkActive = "bg-primaryLight";
const styleSpan = "text-base font-medium text-gray-500 transition duration-500 group-hover:text-primary";
const styleSpanActive = "text-primary";

const Sidebar = () => {
  const location = useLocation();
  const getLink = (path) => {
    return location.pathname.startsWith(path) ? `${styleLinkActive}` : "";
  };
  const getLinkSpan = (path) => {
    return location.pathname.startsWith(path) ? `${styleSpanActive}` : "";
  };

  const [expand, setExpand] = useState({
    entrepreneur: false,
    forecast: true,
    financing: false,
  });
  const toggleExpand = (section) => {
    setExpand((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div style={{ width: "var(--sidebar)" }} className="bg-gray-50 px-4 py-8 box-border border-r-2">
      <>
        <span className="text-lg font-medium">Simplyfi Biz</span>
      </>

      <>
        <button className={styleExpand} onClick={() => toggleExpand("entrepreneur")}>
          <Expand expand={expand.entrepreneur} />
          <Entrepreneur />
          <span className={styleSpan}>Entrepreneur FP</span>
        </button>
      </>

      <>
        <button className={styleExpand} onClick={() => toggleExpand("forecast")}>
          <Expand expand={expand.forecast} />
          <Forecast />
          <span className={styleSpan}>Forecast</span>
        </button>
        {expand.forecast && (
          <>
            <Link to="/dashboard" className={`${styleLink} ${getLink("/dashboard")}`}>
              <Dashboard />
              <span className={`${styleSpan} ${getLinkSpan("/dashboard")}`}>Dashboard</span>
            </Link>
            <Link to="/modeling" className={`${styleLink} ${getLink("/modeling")}`}>
              <Flow />
              <span className={`${styleSpan} ${getLinkSpan("/modeling")}`}>Modeling</span>
            </Link>
            <Link to="/statements" className={`${styleLink} ${getLink("/statements")}`}>
              <Statements />
              <span className={`${styleSpan} ${getLinkSpan("/statements")}`}>Projection</span>
            </Link>
            <Link to="/actual" className={`${styleLink} ${getLink("/actual")}`}>
              <Actual />
              <span className={`${styleSpan} ${getLinkSpan("/actual")}`}>Actual</span>
            </Link>
            <Link to="/compare" className={`${styleLink} ${getLink("/compare")}`}>
              <Compare />
              <span className={`${styleSpan} ${getLinkSpan("/compare")}`}>Compare</span>
            </Link>
            <Link to="/prior" className={`${styleLink} ${getLink("/prior")}`}>
              <Prior />
              <span className={`${styleSpan} ${getLinkSpan("/prior")}`}>Past Data</span>
            </Link>
            <Link to="/valuation" className={`${styleLink} ${getLink("/valuation")}`}>
              <Valuation />
              <span className={`${styleSpan} ${getLinkSpan("/valuation")}`}>Valuation</span>
            </Link>
            <Link to="/report" className={`${styleLink} ${getLink("/report")}`}>
              <Report />
              <span className={`${styleSpan} ${getLinkSpan("/report")}`}>Report</span>
            </Link>
            {/* <Link to="/subscription" className={`${styleLink} ${getLink("/subscription")}`}>
              <Subscription />
              <span className={`${styleSpan} ${getLinkSpan("/subscription")}`}>Subscription</span>
            </Link> */}
          </>
        )}
      </>

      <>
        <button className={styleExpand} onClick={() => toggleExpand("financing")}>
          <Expand expand={expand.financing} />
          <Financing />
          <span className={styleSpan}>Financing</span>
        </button>
      </>
    </div>
  );
};

export default Sidebar;
