import { create } from "zustand";
import { useStore } from "../pages/flow/store";

export const usePriorYearsStore = create((set, get) => ({
  priorYears: [],

  logout() {
    set({ priorYears: [] });
  },

  getPriorLength(node) {
    if (!node) return;

    const priorYears = get().priorYears.find((priorYear) => priorYear.contextId === node.contextId);
    const priorLength = priorYears.priorLength;
    return priorLength;
  },

  updatePriorYearsDetail(data) {
    data.forEach((priorYear) => (priorYear.priorLength = priorYear.headers[0].length - 2));

    set({ priorYears: data });
    const updateAll = useStore.getState().updateAll;
    updateAll();
  },
}));
