import { useState } from "react";
import Table from "./component/Table";

const Summary = ({ setPage }) => {
  const [tab, setTab] = useState("company");

  return (
    <>
      <button
        className="bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded-lg"
        onClick={() => setPage("addCompany")}
      >
        Add Company
      </button>
      <button
        className="bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded-lg"
        onClick={() => setPage("addCampaign")}
      >
        Add Campaign
      </button>

      <>
        <div
          //
          style={{ height: "3rem" }}
          className="flex text-sm font-semibold text-gray-400 gap-2"
        >
          <button
            //
            className={`flex items-center px-12 border-b-2 rounded-t-lg bg-gray-100
              hover:text-primaryStrong hover:border-primaryStrong hover:bg-yellow-50
              ${tab === "company" && "text-primaryStrong border-primaryStrong bg-yellow-50"}`}
            onClick={() => setTab("company")}
          >
            Company
          </button>
          <button
            //
            className={`flex items-center px-12 border-b-2 rounded-t-lg bg-gray-100
              hover:text-primaryStrong hover:border-primaryStrong hover:bg-yellow-50
              ${tab === "campaign" && "text-primaryStrong border-primaryStrong bg-yellow-50"}`}
            onClick={() => setTab("campaign")}
          >
            Campaign
          </button>
        </div>
        <Table tab={tab} setPage={setPage} />
      </>
    </>
  );
};

export default Summary;
