import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { useStore } from "../../store";
import { useAssumptionsStore } from "../../../../stores/useAssumptionsStore";
import { nodeTypesDesc } from "../../constants";
import { nanoid } from "nanoid";
import { Table } from "../utils/Table";
import { NestedList } from "../utils/NestedList";

export const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setInputs: (depth, indices, key, value) => {
    let updatedInputs = [...data.inputsSelf];
    let pointer = updatedInputs;
    for (let i = 0; i < depth; i++) {
      if (i === 0) {
        pointer = pointer[indices[i]];
      } else {
        pointer = pointer.children[indices[i]];
      }
    }

    pointer[key] = value; // pointer: object
    store.updateNode(id, { inputsSelf: updatedInputs });
  },
  setInputsCustom: (depth, indices, key, value) => {
    let updatedInputs = [...data.inputsSelf];
    let pointer = updatedInputs;
    for (let i = 0; i < depth; i++) {
      if (i === 0) {
        pointer = pointer[indices[i]];
      } else {
        pointer = pointer.children[indices[i]];
      }
    }

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "value" && pointer.custom.value === pointer.custom.max) {
      pointer.custom.value = value;
      pointer.custom.max = value;
      store.updateNode(id, { inputsSelf: updatedInputs });
      return;
    }

    pointer.custom[key] = value; // pointer: object
    store.updateNode(id, { inputsSelf: updatedInputs });
  },
  setCustomLong: (depth, indices, colIndex, value) => {
    let updatedInputs = [...data.inputsSelf];
    let pointer = updatedInputs;
    for (let i = 0; i < depth; i++) {
      if (i === 0) {
        pointer = pointer[indices[i]];
      } else {
        pointer = pointer.children[indices[i]];
      }
    }

    if (isNaN(value) || !isFinite(value)) {
      value = null;
    }

    if (value === null) {
      pointer.longCustom[colIndex] = value;
      store.updateNode(id, { inputsSelf: updatedInputs });
      return;
    }

    if (pointer.long[colIndex] !== value) {
      pointer.longCustom[colIndex] = value;
      store.updateNode(id, { inputsSelf: updatedInputs });
    }
  },
  resetCustomLong: (depth, indices) => {
    let updatedInputs = [...data.inputsSelf];
    let pointer = updatedInputs;
    for (let i = 0; i < depth; i++) {
      if (i === 0) {
        pointer = pointer[indices[i]];
      } else {
        pointer = pointer.children[indices[i]];
      }
    }

    pointer.longCustom = pointer.longCustom.map(() => null);
    store.updateNode(id, { inputsSelf: updatedInputs });
  },
  addItem: (depth, indices) => {
    const newItem = {
      id: nanoid(),
      description: "",
      custom: {
        value: 0,
        min: 0,
        max: 0,
        change: 0,
        start: "",
      },
      long: [],
      longCustom: [],
      children: [],
      impact: "",
    };
    let updatedInputs = [...data.inputsSelf];
    let pointer = updatedInputs;
    for (let i = 0; i < depth; i++) {
      pointer = pointer[indices[i]].children;
    }
    pointer.push(newItem); // pointer: array
    store.updateNode(id, { inputsSelf: updatedInputs });
  },
  deleteItem: (depth, indices) => {
    let updatedInputs = [...data.inputsSelf];
    let pointer = updatedInputs;
    for (let i = 0; i < depth - 1; i++) {
      pointer = pointer[indices[i]].children;
    }
    pointer.splice(indices[depth - 1], 1); // pointer: array
    store.updateNode(id, { inputsSelf: updatedInputs });
  },
  sortItems: (sortedItemsIds) => {
    let updatedInputsSelf = [...data.inputsSelf];
    let pointer = updatedInputsSelf;
    let containsItems = pointer.some((item) => item.id === sortedItemsIds[0]);
    let i = 0;
    while (!containsItems) {
      if (i === 0) {
        pointer = pointer[0];
      }
      pointer = pointer.children;
      containsItems = pointer.some((item) => item.id === sortedItemsIds[0]);
      i++;
    }
    const newArray = sortedItemsIds.map((id) => pointer.find((item) => item.id === id));
    pointer.splice(0); // pointer: array
    pointer.push(...newArray);
    store.updateNode(id, { inputsSelf: updatedInputsSelf });
  },
});

const ContCostsDetail = ({ node }) => {
  const { id, data, contextId } = node;
  const { setGeneral, setInputs, setInputsCustom, setCustomLong, resetCustomLong, addItem, deleteItem, sortItems } =
    useStore(selector(id, data));

  const assumptionss = useAssumptionsStore((state) => state.assumptions);
  const assumptions = assumptionss.find((item) => item.contextId === contextId);

  return (
    <div className={RightBarCSS.details}>
      <div>
        <div className={RightBarCSS.container_header}>
          <h2>{nodeTypesDesc[node.type]}</h2>
        </div>

        <>
          <h3>General</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.general}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Description</span>
                <input
                  type="text"
                  name="text"
                  className={RightBarCSS.inputTextLong}
                  value={data.general.description}
                  onChange={(e) => {
                    setGeneral("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>

        <>
          <h3>Inputs (link)</h3>
          <Table items={data.inputsLink} assumptions={assumptions} />
        </>

        <>
          <h3>Inputs (self)</h3>
          <NestedList
            items={data.inputsSelf}
            setInputs={setInputs}
            setInputsCustom={setInputsCustom}
            setCustomLong={setCustomLong}
            resetCustomLong={resetCustomLong}
            addItem={addItem}
            deleteItem={deleteItem}
            sortItems={sortItems}
            assumptions={assumptions}
          />
        </>
      </div>
    </div>
  );
};

export default ContCostsDetail;
