import { styleInner } from "./Dashboard";

const Prior = () => (
  <svg className={styleInner} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 26 26">
    <path
      fill="currentColor"
      d="M13 0L8 3l5 3V4c4.955 0 9 4.045 9 9s-4.045 9-9 9s-9-4.045-9-9c0-2.453.883-4.57 2.5-6.188L5.094 5.407C3.11 7.39 2 10.053 2 13c0 6.045 4.955 11 11 11s11-4.955 11-11S19.045 2 13 2zm-2.094 6.563l-1.812.875l2.531 5A1.539 1.539 0 0 0 11.5 13v.063L8.281 16.28l1.439 1.44l3.219-3.219H13a1.5 1.5 0 0 0 1.5-1.5c0-.69-.459-1.263-1.094-1.438z"
    />
  </svg>
);

export default Prior;
