import Chart from "react-apexcharts";

const GeneralChart = ({ options, series, type }) => {
  return (
    <div className="general-chart">
      <Chart options={options} series={series} type={type} height={300} />
    </div>
  );
};

export default GeneralChart;
