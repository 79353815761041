import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useEffect, useState } from "react";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useStore } from "../flow/store";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../statements/reactgrid/styles.css";
import { getColumns } from "./reactgrid/getColumns";
import { getRows } from "./reactgrid/getRows";

import ButtonDeleteColCellTemplate from "./reactgrid/ButtonDeleteColCellTemplate";
import ButtonDeleteRowCellTemplate from "./reactgrid/ButtonDeleteRowCellTemplate";
import Template from "./components/Header/Template";

export const selector = (contextId) => (store) => ({
  updateData: (headers, rows) => {
    const index = store.priorYears.findIndex((priorYear) => priorYear.contextId === contextId);
    const updated = store.priorYears.map((priorYear, idx) => {
      if (idx === index) {
        return {
          ...priorYear,
          headers,
          rows,
        };
      }
      return priorYear;
    });
    store.updatePriorYearsDetail(updated);
  },
  addColumn: () => {
    const index = store.priorYears.findIndex((priorYear) => priorYear.contextId === contextId);
    const updated = store.priorYears.map((priorYear, idx) => {
      if (idx === index) {
        const newHeaders = priorYear.headers.map((header, headerIndex) => {
          if (headerIndex === 0) {
            return [...header.slice(0, -1), "2000-01", ...header.slice(-1)];
          } else if (headerIndex === 1) {
            return [...header.slice(0, -1), 0, ...header.slice(-1)];
          } else {
            return [...header.slice(0, -1), "", ...header.slice(-1)];
          }
        });
        const newRows = priorYear.rows.map((row) => {
          return [...row.slice(0, -1), 0, ...row.slice(-1)];
        });
        return {
          ...priorYear,
          headers: newHeaders,
          rows: newRows,
        };
      }
      return priorYear;
    });
    store.updatePriorYearsDetail(updated);
  },
  deleteColumn: (columnIndex) => {
    const index = store.priorYears.findIndex((priorYear) => priorYear.contextId === contextId);
    const updated = store.priorYears.map((priorYear, idx) => {
      if (idx === index) {
        const newHeaders = priorYear.headers.map((header) => {
          return header.slice(0, columnIndex).concat(header.slice(columnIndex + 1));
        });
        const newRows = priorYear.rows.map((row) => {
          return row.slice(0, columnIndex).concat(row.slice(columnIndex + 1));
        });
        return {
          ...priorYear,
          headers: newHeaders,
          rows: newRows,
        };
      }
      return priorYear;
    });
    store.updatePriorYearsDetail(updated);
  },
  addRow: () => {
    const index = store.priorYears.findIndex((priorYear) => priorYear.contextId === contextId);
    const updated = store.priorYears.map((priorYear, idx) => {
      if (idx === index) {
        const newRow = ["", ...Array.from({ length: priorYear.headers[0].length - 2 }, () => 0), "N/A"];
        return {
          ...priorYear,
          rows: [...priorYear.rows, newRow],
        };
      }
      return priorYear;
    });
    store.updatePriorYearsDetail(updated);
  },
  deleteRow: (rowIndex) => {
    const index = store.priorYears.findIndex((priorYear) => priorYear.contextId === contextId);
    const updated = store.priorYears.map((priorYear, idx) => {
      if (idx === index) {
        const newRows = [...priorYear.rows];
        newRows.splice(rowIndex, 1);
        return {
          ...priorYear,
          rows: newRows,
        };
      }
      return priorYear;
    });
    store.updatePriorYearsDetail(updated);
  },
  deleteAllRows: () => {
    const index = store.priorYears.findIndex((priorYear) => priorYear.contextId === contextId);
    const updated = store.priorYears.map((priorYear, idx) => {
      if (idx === index) {
        return {
          ...priorYear,
          rows: [],
        };
      }
      return priorYear;
    });
    store.updatePriorYearsDetail(updated);
  },
});

const Prior = ({ priorYears }) => {
  const contextId = useStore((state) => state.contextId);
  const { autoSave } = useSaveContext();
  const { updateData, addColumn, deleteColumn, addRow, deleteRow, deleteAllRows } = usePriorYearsStore(
    selector(contextId)
  );

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const { priorLength, headers: headersData, rows: rowsData } = priorYears;

  useEffect(() => {
    if (!contextId) {
      return;
    }

    const monthsDetails = { priorLength };
    const abc = getColumns({ monthsDetails });
    const def = getRows({ monthsDetails, headersData, rowsData });

    setColumns(abc);
    setRows(def);
  }, [contextId, priorLength, headersData, rowsData]);

  const handleChanges = (changes) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      changes.forEach((change) => {
        const col = change.columnId;
        const row = change.rowId;
        const newCell = change.newCell;

        if (row === 0) {
          // date
          const match = newCell.text.match(/^(\d{4})-(\d{1,2})$/);
          if (match) {
            const year = match[1];
            let month = match[2];

            if (month >= 1 && month <= 12) {
              month = month.padStart(2, "0");
              const normalizedText = `${year}-${month}`;
              newCell.text = normalizedText;
              updatedRows[row + 1].cells[col] = newCell;
            }
          }
        } else if (row === 1) {
          // months
          if (newCell.value > 0) {
            updatedRows[row + 1].cells[col] = newCell;
          }
        } else {
          updatedRows[row + 1].cells[col] = newCell;
        }
      });

      const newHeaders = [];
      const newRows = [];
      const colLength = updatedRows[0].cells.length - 1;
      updatedRows.forEach((row, index) => {
        const item = row.cells.slice(0, colLength).map((cell) => {
          if (cell.type === "number") {
            return cell.value || 0;
          } else if (cell.type === "text") {
            return cell.text || "";
          } else if (cell.type === "dropdown") {
            return cell.selectedValue || "N/A";
          } else {
            return null;
          }
        });

        if (index === 0) {
          // do nothing, this is the delete col index
        } else if (index <= 3) {
          newHeaders.push(item);
        } else {
          newRows.push(item);
        }
      });

      const hasChanged = changes[0].previousCell.selectedValue !== changes[0].newCell.selectedValue;
      if (changes[0].type === "dropdown") {
        if (hasChanged) {
          updateData(newHeaders, newRows);
          //autoSave();
        }
      } else {
        updateData(newHeaders, newRows);
        //autoSave();
      }

      return updatedRows;
    });
    autoSave();
  };

  const handleAddCol = () => {
    addColumn();
    autoSave();
  };

  const handleDeleteCol = (cell) => {
    deleteColumn(cell.colIndex);
    autoSave();
  };

  const handleAddRow = () => {
    addRow();
    autoSave();
  };

  const handleDeleteRow = (cell) => {
    deleteRow(cell.rowIndex);
    autoSave();
  };

  const handleDeleteAllRows = () => {
    deleteAllRows();
    autoSave();
  };

  const deleteColButtonInstance = new ButtonDeleteColCellTemplate();
  deleteColButtonInstance.props = { onClick: handleDeleteCol };

  const deleteRowButtonInstance = new ButtonDeleteRowCellTemplate();
  deleteRowButtonInstance.props = { onClick: handleDeleteRow };

  return (
    <div className={StatementsCSS.tableContainer}>
      <>
        <div>
          <Template contextId={contextId} />
        </div>
        <div>
          <button onClick={handleAddCol}>add col</button>
          <button onClick={handleAddRow}>add row</button>
          <button onClick={handleDeleteAllRows}>delete all rows</button>
        </div>
      </>
      <h1>Past Data</h1>
      <div className="outer-prior">
        <div className="reactgrid">
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges}
            stickyTopRows={4}
            stickyLeftColumns={1}
            enableRangeSelection
            customCellTemplates={{ deleteColButton: deleteColButtonInstance, deleteRowButton: deleteRowButtonInstance }}
          />
        </div>
      </div>
    </div>
  );
};

export default Prior;
