import ContNormalNode from "./containerNodes/nodes/ContNormalNode";
import ContStaffNode from "./containerNodes/nodes/ContStaffNode";
import ContCostsNode from "./containerNodes/nodes/ContCostsNode";
import ContCapexNode from "./containerNodes/nodes/ContCapexNode";
import ContLoanNode from "./containerNodes/nodes/ContLoanNode";

import ButtonEdge from "./edges/ButtonEdge";
export const edgeTypes = {
  buttonedge: ButtonEdge,
};

export const nodeTypesDesc = {
  contBlank: "Blank",
  contMarketing: "Marketing",
  contSales: "Sales",
  contRevenue: "Revenue",
  contStaff: "Staff",
  contDirectCosts: "Direct Costs",
  contIndirectCosts: "Indirect Costs",
  contExpenses: "Expenses",
  contProfit: "Profit / (Loss)",
  contCapex: "CAPEX",
  contLoan: "Loan",
  contReceivables: "Receivables",
  contPayables: "Payables",
  contShareCap: "Share Capital",
  contRetained: "Retained earnings",
  contCash: "Cash",
  contSofp: "SOFP",
};

export const nodeTypes = {
  contBlank: ContNormalNode,
  contMarketing: ContNormalNode,
  contSales: ContNormalNode,
  contRevenue: ContNormalNode,
  contStaff: ContStaffNode,
  contDirectCosts: ContCostsNode,
  contIndirectCosts: ContCostsNode,
  contExpenses: ContNormalNode,
  contProfit: ContNormalNode,
  contCapex: ContCapexNode,
  contLoan: ContLoanNode,
  contReceivables: ContNormalNode,
  contPayables: ContNormalNode,
  contShareCap: ContNormalNode,
  contRetained: ContNormalNode,
  contCash: ContNormalNode,
  contSofp: ContNormalNode,
};

export const selector = (contextId) => (store) => {
  return {
    addContBlank: (x, y) => store.createNode("contBlank", "blank", contextId, x, y),
    addContMarketing: (x, y) => store.createNode("contMarketing", "marketing", contextId, x, y),
    addContSales: (x, y) => store.createNode("contSales", "sales", contextId, x, y),
    addContRevenue: (x, y) => store.createNode("contRevenue", "revenue", contextId, x, y),
    addContStaff: (x, y) => store.createNode("contStaff", "staff", contextId, x, y),
    addContDirectCosts: (x, y) => store.createNode("contDirectCosts", "directCosts", contextId, x, y),
    addContIndirectCosts: (x, y) => store.createNode("contIndirectCosts", "indirectCosts", contextId, x, y),
    addContExpenses: (x, y) => store.createNode("contExpenses", "expenses", contextId, x, y),
    addContProfit: (x, y) => store.createNode("contProfit", "profit", contextId, x, y),
    addContCapex: (x, y) => store.createNode("contCapex", "capex", contextId, x, y),
    addContLoan: (x, y) => store.createNode("contLoan", "loan", contextId, x, y),
    addContReceivables: (x, y) => store.createNode("contReceivables", "receivables", contextId, x, y),
    addContPayables: (x, y) => store.createNode("contPayables", "payables", contextId, x, y),
    addContShareCap: (x, y) => store.createNode("contShareCap", "shareCap", contextId, x, y),
    addContRetained: (x, y) => store.createNode("contRetained", "retained", contextId, x, y),
    addContCash: (x, y) => store.createNode("contCash", "cash", contextId, x, y),
    addContSofp: (x, y) => store.createNode("contSofp", "sofp", contextId, x, y),
  };
};
