import { useState } from "react";
import { useLogin } from "../auth/useLogin";
import { useNavigate, useLocation } from "react-router-dom";

export const styleInput = "block w-full p-2 my-2 border border-gray-400 rounded-xl";
export const styleButton = "w-full bg-primary text-white rounded-xl my-1 p-3";
export const styleError = "p-2 border border-error text-error rounded-xl my-2";

const Login = ({ toSignupPage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  const handleClick = (e) => {
    e.preventDefault();
    toSignupPage();
  };

  const handleOauth = () => {
    sessionStorage.setItem("path", location.pathname);
    navigate("/redirect");
  };

  return (
    // <div className="absolute my-10 mx-auto p-5 bg-white rounded-2xl">
    <div className="w-96 bg-white absolute p-5 rounded-xl top-28 right-8">
      <p className="font-semibold text-2xl">Sign in to Simplyfi Forecast</p>
      <p className="text-gray-500 mb-8">Create a profile to save your progress!</p>

      {process.env.REACT_APP_NODE_ENV === "production" && (
        <button className={styleButton} onClick={handleOauth}>
          Log In with Simplyfi Hub
        </button>
      )}

      {process.env.REACT_APP_NODE_ENV === "development" && (
        <>
          <>
            <p>Login</p>
            <input
              className={styleInput}
              type="email"
              id="email"
              autoComplete="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className={styleInput}
              type="password"
              id="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className={styleButton} disabled={isLoading} onClick={handleLogin}>
              Log In
            </button>
            {error && <div className={styleError}>{error}</div>}
          </>

          <div className="text-center mt-2">
            <span>Don't have an account?&nbsp;</span>
            <span className="text-primary cursor-pointer" onClick={handleClick}>
              Sign Up
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
