import { useStore } from "../store";
import { useReportStore } from "../../../stores/useReportStore";

export const updateReport = () => {
  const contexts = useStore.getState().contexts;
  const reports = useReportStore.getState().report;

  // remove deleted context
  const contextIds = contexts.map((context) => context.id);
  for (let i = reports.length - 1; i >= 0; i--) {
    if (!contextIds.includes(reports[i].contextId)) {
      reports.splice(i, 1);
    }
  }

  // add new context
  contexts.forEach((context) => {
    const reportIndex = reports.findIndex((difference) => difference.contextId === context.id);
    if (reportIndex === -1) {
      reports.push({
        contextId: context.id,
        companyName: "",
        summary: "",
      });
    }
  });
};
