import MascotResting from "./MascotResting";

const NoContextSelected = () => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center overflow-y-auto bg-white rounded-3xl">
      <MascotResting />
      <p className="font-semibold text-gray-600 text-lg mt-8 mb-2">Select a company/campaign for viewing!</p>
      <p className="font-medium text-gray-500 mb-6">Select from the top left corner.</p>
    </div>
  );
};

export default NoContextSelected;
