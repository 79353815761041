import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useAxiosPrivate } from "../../axios/useAxiosPrivate";

import { useAuthContext } from "../../auth/useAuthContext";
import { useLogout } from "../../auth/useLogout";
import { useGeneralStore } from "../../stores/useGeneralStore";

import Burger from "./logo/Burger";
import LogoPic from "./logo/LogoPic";
import LogoWord from "./logo/LogoWord";

import Hub from "./link/1Hub";
import Personal from "./link/2Personal";
import Biz from "./link/3Biz";
import Investor from "./link/4Investor";
import Partner from "./link/5Partner";

const styleLeft = "flex gap-4";
const styleSpan = "flex items-center text-white h-12 border-2 border-primary px-4 py-2 bg-primary";
const styleButton = "text-primary h-12 border-2 border-primary px-4 py-2";

const Navbar = ({ hasSidebar, setHasSidebar }) => {
  const { user } = useAuthContext();
  const { logout } = useLogout();

  const axiosPrivate = useAxiosPrivate();
  const subscription = useGeneralStore((state) => state.subscription);
  const setSubscription = useGeneralStore.getState().setSubscription;

  useEffect(() => {
    const getSubscription = async () => {
      const response = await axiosPrivate.get("/api/subscriptions");
      setSubscription(response.data);
    };

    if (user) {
      getSubscription();
    }
  }, [user, axiosPrivate, setSubscription]);

  return (
    <div style={{ height: "var(--navbar)" }} className="flex justify-between bg-white px-4 border-b-2">
      <div className="flex gap-8 items-center">
        <div className={styleLeft}>
          <Burger hasSidebar={hasSidebar} setHasSidebar={setHasSidebar} />
        </div>

        <div className={styleLeft}>
          <Link to="/">
            <LogoPic />
          </Link>
          <Link to="/">
            <LogoWord />
          </Link>
        </div>

        <div className={styleLeft}>
          <Link to="/">
            <Hub active={true} />
          </Link>
          <Link to="/">
            <Personal active={false} />
          </Link>
          <Link to="/">
            <Biz active={false} />
          </Link>
          <Link to="/">
            <Investor active={false} />
          </Link>
          <Link to="/">
            <Partner active={false} />
          </Link>
        </div>
      </div>

      {user && (
        <div className="flex gap-8 items-center">
          {user.email && (
            <>
              {subscription && <span className={styleSpan}>{subscription.name}</span>}
              <span>{user.email}</span>
              <button className={styleButton} onClick={() => logout(true)}>
                Logout
              </button>
            </>
          )}
          {!user.email && (
            <>
              {subscription && <span className={styleSpan}>{subscription.name}</span>}
              <span>Guest</span>
              <Link to="/signupGuest">
                <button className={styleButton}>Signup</button>
              </Link>
              <button className={styleButton} onClick={() => logout(true)}>
                Logout
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
