import { useEffect, useState } from "react";
import { useStore } from "../store";

const EditCampaign = ({ setPage }) => {
  const [formData, setFormData] = useState({
    type: "campaign",
    name: "",
    owner: "",
  });

  const { contextId, contexts, setContextId, editContext } = useStore.getState();
  const context = contexts.find((context) => context.id === contextId);

  useEffect(() => {
    if (context) {
      setFormData({
        type: context.data.type,
        name: context.data.name,
        owner: context.data.owner,
      });
    }
  }, [context]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleEdit = () => {
    editContext(contextId, formData);
    setContextId(null);
    setPage("summary");
  };

  const handleCancel = () => {
    setContextId(null);
    setPage("summary");
  };

  return (
    <div className="flex flex-col h-full p-4">
      <h1 className="text-xl font-semibold mb-4">Edit Campaign</h1>

      <div className="flex-grow flex flex-col gap-2">
        <h2 className="text-lg font-medium">General Information</h2>

        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <h3 className="font-semibold text-secondary">Campaign Name</h3>
            <input
              type="text"
              id="name"
              autoComplete="name"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">Campaign Owner</h3>
            <input
              type="text"
              id="owner"
              autoComplete="owner"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter owner"
              value={formData.owner}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">xxx</h3>
            <input
              type="text"
              id="xxx"
              autoComplete="xxx"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter xxx"
              value={formData.xxx}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">yyy</h3>
            <input
              type="text"
              id="yyy"
              autoComplete="yyy"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter yyy"
              value={formData.yyy}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <h3 className="font-semibold text-secondary">Objective</h3>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2"
            id="objective"
            placeholder="Enter objective"
            rows="4"
          />
        </div>

        <div>
          <h3 className="font-semibold text-secondary">Notes</h3>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2"
            id="notes"
            placeholder="Enter notes"
            rows="4"
          />
        </div>
      </div>

      <div className="flex justify-between">
        <button
          className="px-8 py-2 border border-primaryStrong text-primaryStrong font-medium rounded-xl hover:bg-primaryLight"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="px-8 py-2 border bg-primaryStrong text-white font-medium rounded-xl hover:bg-primary"
          onClick={handleEdit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditCampaign;
