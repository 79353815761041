import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";
import { useStore } from "../flow/store";
import { useAssumptionsStore } from "../../stores/useAssumptionsStore";
import { useDifferencesStore } from "../../stores/useDifferencesStore";
import NoContext from "../../components/noSubscription/NoContext";
import NoContextSelected from "../../components/noSubscription/NoContextSelected";

import SOFP from "./SOFP";
import SOPL from "./SOPL";
import SOCF from "./SOCF";

const Route = () => {
  const [page, setPage] = useState("SOFP");

  const contexts = useStore((state) => state.contexts);
  const contextId = useStore((state) => state.contextId);
  const assumptions = useAssumptionsStore((state) => state.assumptions);
  const differencess = useDifferencesStore((state) => state.differences);

  const assumption = assumptions.find((item) => item.contextId === contextId);
  const differences = differencess.find((item) => item.contextId === contextId);

  if (contexts.length === 0) {
    return <NoContext />;
  }
  if (!contextId) {
    return <NoContextSelected />;
  }

  return (
    <div className="flex-grow overflow-y-auto p-4 bg-white rounded-3xl">
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "SOFP" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOFP")}
        >
          SOFP
        </button>
        <button
          className={`${StatementsCSS.route} ${page === "SOPL" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOPL")}
        >
          SOPL
        </button>
        <button
          className={`${StatementsCSS.route} ${page === "SOCF" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOCF")}
        >
          SOCF
        </button>
      </div>

      {page === "SOFP" && <SOFP assumption={assumption} differences={differences} />}
      {page === "SOPL" && <SOPL assumption={assumption} differences={differences} />}
      {page === "SOCF" && <SOCF assumption={assumption} differences={differences} />}
    </div>
  );
};

export default Route;
