import { useStore } from "../../store";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";

// import FileSaver from "file-saver";

const HeaderGenerateExample = ({ contextId }) => {
  const generateExample = useStore.getState().generateExample;

  // save
  const { autoSave } = useSaveContext();
  const generateEmpty = () => {
    generateExample(contextId, "empty");
    autoSave();
  };
  const generateFilled = () => {
    generateExample(contextId, "filled");
    autoSave();
  };

  // const onSaveToFile = () => {
  //   const nodes = useStore
  //     .getState()
  //     .nodes.filter((node) => node.contextId === contextId)
  //     .map((node) => ({ ...node, contextId: "" }));
  //   const edges = useStore
  //     .getState()
  //     .edges.filter((edge) => edge.contextId === contextId)
  //     .map((edge) => ({ ...edge, contextId: "" }));
  //   const flow = { nodes: nodes, edges: edges };
  //   const blob = new Blob([JSON.stringify(flow, null, 2)], { type: "application/json" });
  //   FileSaver.saveAs(blob, "empty-example.json");
  // };

  return (
    <div>
      {/* <button onClick={onSaveToFile}>Save to File</button> */}
      <button className="border border-black" onClick={generateEmpty}>
        Empty Example
      </button>
      <button className="border border-black" onClick={generateFilled}>
        Filled Example
      </button>
    </div>
  );
};

export default HeaderGenerateExample;
