import { useState } from "react";
import GeneralChart from "./Chart";
import InfoCircle from "./InfoCircle";

const FundingSourcesChart = () => {
  const [isHovered, setIsHovered] = useState(false);

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: ["#775DD0", "#00E396", "#FFB800"],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "60%",
        borderRadius: 4,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Apple", "Lenovo", "Asus"],
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => val.toLocaleString(),
      },
    },
  };

  const series = [
    { name: "Source 1", data: [40, 50, 70] },
    { name: "Source 2", data: [70, 80, 30] },
    { name: "Source 3", data: [30, 40, 80] },
  ];

  return (
    <>
      <div className="flex items-center gap-2">
        <svg
          className="text-primary h-8 w-auto"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V11C1 11.2652 1.10536 11.5196 1.29289 11.7071C1.48043 11.8946 1.73478 12 2 12H5V21C5 21.2652 5.10536 21.5196 5.29289 21.7071C5.48043 21.8946 5.73478 22 6 22H18C18.2652 22 18.5196 21.8946 18.7071 21.7071C18.8946 21.5196 19 21.2652 19 21V12H22C22.2652 12 22.5196 11.8946 22.7071 11.7071C22.8946 11.5196 23 11.2652 23 11V3C23 2.73478 22.8946 2.48043 22.7071 2.29289C22.5196 2.10536 22.2652 2 22 2ZM7 20V18C7.53043 18 8.03914 18.2107 8.41421 18.5858C8.78929 18.9609 9 19.4696 9 20H7ZM17 20H15C15 19.4696 15.2107 18.9609 15.5858 18.5858C15.9609 18.2107 16.4696 18 17 18V20ZM17 16C15.9391 16 14.9217 16.4214 14.1716 17.1716C13.4214 17.9217 13 18.9391 13 20H11C11 18.9391 10.5786 17.9217 9.82843 17.1716C9.07828 16.4214 8.06087 16 7 16V8H17V16ZM21 10H19V7C19 6.73478 18.8946 6.48043 18.7071 6.29289C18.5196 6.10536 18.2652 6 18 6H6C5.73478 6 5.48043 6.10536 5.29289 6.29289C5.10536 6.48043 5 6.73478 5 7V10H3V4H21V10ZM12 15C12.5933 15 13.1734 14.8241 13.6667 14.4944C14.1601 14.1648 14.5446 13.6962 14.7716 13.1481C14.9987 12.5999 15.0581 11.9967 14.9424 11.4147C14.8266 10.8328 14.5409 10.2982 14.1213 9.87868C13.7018 9.45912 13.1672 9.1734 12.5853 9.05764C12.0033 8.94189 11.4001 9.0013 10.8519 9.22836C10.3038 9.45542 9.83524 9.83994 9.50559 10.3333C9.17595 10.8266 9 11.4067 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15ZM12 11C12.1978 11 12.3911 11.0586 12.5556 11.1685C12.72 11.2784 12.8482 11.4346 12.9239 11.6173C12.9996 11.8 13.0194 12.0011 12.9808 12.1951C12.9422 12.3891 12.847 12.5673 12.7071 12.7071C12.5673 12.847 12.3891 12.9422 12.1951 12.9808C12.0011 13.0194 11.8 12.9996 11.6173 12.9239C11.4346 12.8482 11.2784 12.72 11.1685 12.5556C11.0586 12.3911 11 12.1978 11 12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11Z"
            fill="orange"
          />
        </svg>
        <h3 className="text-lg font-semibold">Funding Sources</h3>
        <div
          className="relative cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <InfoCircle className="text-gray-300 h-6 w-auto" />
          {isHovered && (
            <div className="absolute text-sm top-[-0.8rem] left-[1.8rem] bg-blue-950 text-white p-2 rounded-lg shadow-lg w-[20rem] z-10">
              Discover potential avenues for financing, from traditional investments to innovative funding solutions, to
              support your financial strategy.
              <div className="absolute top-[1.5rem] left-[-1rem] transform -translate-y-1/2 border-[10px] border-transparent border-r-blue-900"></div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex justify-around w-4/5">
        {options.colors.map((color, index) => (
          <div key={index}>
            <h3 className="m-0" style={{ color: color }}>
              Source {index + 1}
            </h3>
            <div className="flex items-center">
              <p className="text-2xl m-0 font-medium">00</p>
              <h4 className="text-gray-500 font-normal pl-2">Sales</h4>
            </div>
          </div>
        ))}
      </div> */}
      <GeneralChart options={options} series={series} type="bar" />
    </>
  );
};

export default FundingSourcesChart;
