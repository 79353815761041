import { useState } from "react";
import { useAssumptionsStore } from "../../../../stores/useAssumptionsStore";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";
import { ModalEditAssumptions } from "./modal//ModalEditAssumptions";
import { Tooltip } from "@mui/material";

const HeaderSettings = ({ contextId }) => {
  const updateAssumptions = useAssumptionsStore.getState().updateAssumptions;
  const assumptions = useAssumptionsStore((state) => state.assumptions);
  const assumption = assumptions.find((item) => item.contextId === contextId);

  // save
  const { autoSave } = useSaveContext();
  const handleSetAssumptions = (key, value) => {
    updateAssumptions(contextId, key, value);
    autoSave();
  };

  // modal
  const [modalState, setModalState] = useState(false);
  const handleOpenModal = (status) => {
    setModalState(status);
  };
  const handleCloseModal = () => {
    setModalState(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <svg
          className="h-8 w-auto cursor-pointer"
          onClick={() => handleOpenModal(true)}
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m9.25 22l-.4-3.2q-.325-.125-.612-.3t-.563-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2zm2.8-6.5q1.45 0 2.475-1.025T15.55 12t-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12t1.013 2.475T12.05 15.5"
          />
        </svg>
      </Tooltip>
      {modalState && (
        <ModalEditAssumptions
          open={true}
          handleClose={handleCloseModal}
          assumption={assumption}
          setAssumptions={handleSetAssumptions}
        />
      )}
    </>
  );
};

export default HeaderSettings;
