import { useStore } from "../store";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";

export const updatePriorYears = () => {
  const contexts = useStore.getState().contexts;
  const priorYears = usePriorYearsStore.getState().priorYears;

  // remove deleted context
  const contextIds = contexts.map((context) => context.id);
  for (let i = priorYears.length - 1; i >= 0; i--) {
    if (!contextIds.includes(priorYears[i].contextId)) {
      priorYears.splice(i, 1);
    }
  }

  // add new context
  contexts.forEach((context) => {
    const priorYearIndex = priorYears.findIndex((priorYear) => priorYear.contextId === context.id);
    if (priorYearIndex === -1) {
      priorYears.push({
        contextId: context.id,
        priorLength: 3,
        headers: [
          ["Period ending", "2021-12", "2022-12", "2023-12", " "],
          ["Number of months", 12, 12, 12, " "],
          ["Description", "", "", "", "Link to container"],
        ],
        rows: [
          ["", 0, 0, 0, "N/A"],
          ["", 0, 0, 0, "N/A"],
          ["", 0, 0, 0, "N/A"],
        ],
      });
    }
  });
};
