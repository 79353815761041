import { useCallback, useEffect } from "react";
import { useAxiosPrivate } from "../axios/useAxiosPrivate";

import { useStore } from "../pages/flow/store";
import { usePriorYearsStore } from "../stores/usePriorYearsStore";
import { useAssumptionsStore } from "../stores/useAssumptionsStore";
import { useStatementsStore } from "../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../stores/useStatementsAnnualStore";
import { useActualStore } from "../stores/useActualStore";
import { useDifferencesStore } from "../stores/useDifferencesStore";
import { useValuationStore } from "../stores/useValuationStore";
import { useDashboardStore } from "../stores/useDashboardStore";
import { useReportStore } from "../stores/useReportStore";

export const useRestore = (user) => {
  const axiosPrivate = useAxiosPrivate();

  const onRestore = useCallback(async (axiosPrivate) => {
    const [flowResponse, statementResponse, dashboardResponse, reportResponse] = await Promise.all([
      axiosPrivate.get("/api/flows"),
      axiosPrivate.get("/api/statements"),
      axiosPrivate.get("/api/dashboards"),
      axiosPrivate.get("/api/reports"),
    ]);

    const response1 = flowResponse.data;
    const response2 = statementResponse.data;
    const response3 = dashboardResponse.data;
    const response4 = reportResponse.data;

    if (response1) {
      useStore.setState(
        (prev) => ({
          ...prev,
          contexts: response1.contexts ?? prev.contexts,
          nodes: response1.nodes ?? prev.nodes,
          edges: response1.edges ?? prev.edges,
        }),
        false
      );
      usePriorYearsStore.setState(
        (prev) => ({
          priorYears: response1.priorYears ?? prev.priorYears,
        }),
        false
      );
      useAssumptionsStore.setState(
        (prev) => ({
          assumptions: response1.assumptions ?? prev.assumptions,
        }),
        false
      );
    }

    if (response2) {
      useStatementsStore.setState(
        (prev) => ({
          statements: response2.statements ?? prev.statements,
        }),
        false
      );
      useStatementsAnnualStore.setState(
        (prev) => ({
          statementsAnnual: response2.statementsAnnual ?? prev.statementsAnnual,
        }),
        false
      );
      useActualStore.setState(
        (prev) => ({
          actual: response2.actual ?? prev.actual,
        }),
        false
      );
      useDifferencesStore.setState(
        (prev) => ({
          differences: response2.differences ?? prev.differences,
        }),
        false
      );
      useValuationStore.setState(
        (prev) => ({
          valuation: response2.valuation ?? prev.valuation,
        }),
        false
      );
    }

    if (response3) {
      useDashboardStore.setState(
        (prev) => ({
          dashboard: response3.dashboard ?? prev.dashboard,
        }),
        false
      );
    }

    if (response4) {
      useReportStore.setState(
        (prev) => ({
          report: response4.report ?? prev.report,
        }),
        false
      );
    }

    useStore.getState().updateAll();
  }, []);

  useEffect(() => {
    if (user) {
      onRestore(axiosPrivate);
    }
  }, [user, onRestore, axiosPrivate]);
};
