import { useStore } from "../../pages/flow/store";

const SelectContext = () => {
  const contextId = useStore((state) => state.contextId);
  const contexts = useStore((state) => state.contexts);
  const setContextId = useStore.getState().setContextId;

  const handleSelectStatement = (contextId) => {
    setContextId(contextId === "" ? null : contextId);
  };

  return (
    <div className="flex h-10">
      {contexts.length === 0 ? (
        <span className="flex items-center min-w-56 h-full px-2 text-gray-700 bg-white border border-gray-300 rounded-lg">
          Create One!
        </span>
      ) : (
        <select
          className="min-w-56 h-full px-2 text-gray-700 bg-white border border-gray-300 rounded-lg"
          name="select"
          value={contextId || ""}
          onChange={(e) => handleSelectStatement(e.target.value)}
        >
          <option value="">Pick One!</option>
          {contexts.map((context, index) => (
            <option key={index} value={context.id}>
              {context.data.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default SelectContext;
