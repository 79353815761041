import { styleInner } from "./Dashboard";

const Valuation = () => (
  <svg className={styleInner} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      color="currentColor"
    >
      <path d="M21 21H10c-3.3 0-4.95 0-5.975-1.025S3 17.3 3 14V3" />
      <path d="m17.705 9.333l-2.874 4.652c-.419.677-.894 1.701-1.756 1.55c-1.014-.179-1.5-1.69-2.372-2.19c-.71-.408-1.223.083-1.638.655M21 4l-1.854 3M5 20l2.526-3.733" />
    </g>
  </svg>
);

export default Valuation;
