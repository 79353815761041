import { useState } from "react";
import GeneralChart from "./Chart";
import InfoCircle from "./InfoCircle";

const SummaryOverview = ({ annual, xaxisLabels }) => {
  const [isHovered, setIsHovered] = useState(false);

  const totalAssetsLong = annual.sofp.find((item) => item.description === "totalAssetsLong").long;
  const totalEquityLong = annual.sofp.find((item) => item.description === "totalEquityLong").long;
  const totalLiabilitiesLong = annual.sofp.find((item) => item.description === "totalLiabilitiesLong").long;

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: xaxisLabels,
    },
    yaxis: {
      labels: {
        formatter: (val) => `$${val.toLocaleString()}`,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toLocaleString()}`,
      },
    },
    colors: ["#845ef7", "#4CAF50", "#ff9800"],
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };

  const seriesData = [
    {
      name: "Total Assets",
      data: totalAssetsLong,
    },
    {
      name: "Total Equity",
      data: totalEquityLong,
    },
    {
      name: "Total Liabilities",
      data: totalLiabilitiesLong,
    },
  ];

  return (
    <>
      <div className="flex items-center gap-2">
        <svg
          className="text-primary h-8 w-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
          />
        </svg>
        <h3 className="text-lg font-semibold">Summary Overview</h3>
        <div
          className="relative cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <InfoCircle className="text-gray-300 h-6 w-auto" />
          {isHovered && (
            <div className="absolute text-sm top-[-0.8rem] left-[1.8rem] bg-blue-950 text-white p-2 rounded-lg shadow-lg w-[20rem] z-10">
              Get a concise snapshot of your project's goal, achievements, and strategic direction to stay align with
              your vision.
              <div className="absolute top-[1.5rem] left-[-1rem] transform -translate-y-1/2 border-[10px] border-transparent border-r-blue-900"></div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex items-center">
        <span className="text-2xl font-semibold">00</span>
      </div> */}
      <GeneralChart options={chartOptions} series={seriesData} type="bar" />
    </>
  );
};

export default SummaryOverview;
