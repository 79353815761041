import { useEffect, useState } from "react";
import { useStore } from "./store";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";
import Panel from "./components/Panel";
import FlowHandlers from "./FlowHandlers";

import "@xyflow/react/dist/style.css";
import { ReactFlowProvider, ReactFlow, Background, Controls, MiniMap } from "@xyflow/react";
import { useNodesState, useEdgesState } from "@xyflow/react";
import { selector, nodeTypes, edgeTypes } from "./constants";

import { getColor } from "./minimapColors";
import { onDragStart, onDragOver, onDrop } from "./utilsFunctions/dragAndDrop";
import RightBar from "./RightBar";

const View = ({ contextId }) => {
  const store = useStore(selector(contextId));
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { autoSave } = useSaveContext();
  const [selectedId, setSelectedId] = useState(null);

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  const { filteredNodes, filteredEdges, handleNodesChange, handleEdgesChange, handleConnect } = FlowHandlers({
    contextId,
    onNodesChange,
    onEdgesChange,
    autoSave,
    setSelectedId,
  });

  useEffect(() => {
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  }, [filteredNodes, filteredEdges, setNodes, setEdges]);

  return (
    <ReactFlowProvider>
      <ReactFlow
        // normal
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onConnect={handleConnect}
        // dnd
        onInit={setReactFlowInstance}
        onDrop={(event) => onDrop(event, reactFlowInstance, store)}
        onDragOver={onDragOver}
        // ui/ux
        fitView
        deleteKeyCode={["Backspace", "Delete"]}
      >
        <Panel contextId={contextId} onDragStart={onDragStart} />
        <Background />
        <Controls />
        <MiniMap nodeColor={(n) => getColor(n.type, 0.4)} />
        <RightBar id={selectedId} />
      </ReactFlow>
    </ReactFlowProvider>
  );
};

export default View;
