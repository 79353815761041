import { useStore } from "../store";
import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";

export const updateAssumptions = () => {
  const contexts = useStore.getState().contexts;
  const priorYears = usePriorYearsStore.getState().priorYears;
  const assumptions = useAssumptionsStore.getState().assumptions;

  // remove deleted context
  const contextIds = contexts.map((context) => context.id);
  for (let i = assumptions.length - 1; i >= 0; i--) {
    if (!contextIds.includes(assumptions[i].contextId)) {
      assumptions.splice(i, 1);
    }
  }

  // add new context
  contexts.forEach((context) => {
    const index = assumptions.findIndex((item) => item.contextId === context.id);
    if (index === -1) {
      assumptions.push({
        // user input
        contextId: context.id,
        currency: "$",
        startDate: "2024-1",
        periodEnd: 12, // dec year end
        months: 24,
        // calculate
        priorLength: 0,
        // monthly
        totalMonths: 0,
        monthsNames: [],
        monthsArray: [],
        monthsNumbers: [],
        // annually
        totalPeriod: 0,
        monthsNamesAnnual: [],
        monthsArrayAnnual: [],
        monthsNumbersAnnual: [],
      });
    }
  });

  assumptions.forEach((assumption, index) => {
    // initialise for use
    const { startDate, periodEnd, months } = assumption;
    const priorYear = priorYears[index];
    const { priorLength } = priorYear;

    // calculate
    assumption.priorLength = priorLength;

    // monthly
    const totalMonths = months + priorLength;
    assumption.totalMonths = totalMonths;
    assumption.monthsNames = getMonthsNames(startDate, months, priorYear);
    assumption.monthsArray = getMonthsArray(months, priorLength);
    assumption.monthsNumbers = getMonthsNumbers(months, priorYear);

    // annually
    const totalPeriod = getTotalPeriod(startDate, periodEnd, months, priorLength);
    assumption.totalPeriod = totalPeriod;
    const monthsDetails = { startDate, periodEnd, priorYear, priorLength, totalMonths, totalPeriod };
    assumption.monthsNamesAnnual = getMonthsNames2(monthsDetails);
    assumption.monthsArrayAnnual = getMonthsArray2(monthsDetails);
    assumption.monthsNumbersAnnual = getMonthsNumbers2(monthsDetails);
  });
};

const formatDate = (dateString) => {
  const [year, month] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
};

const getDate = (year, month) => {
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
};

const getMonthsNames = (startDate, months, priorYear) => {
  // b/f
  const dateStrings = priorYear.headers[0].slice(1, -1);
  const bfNames = dateStrings.map((dateString) => formatDate(dateString));

  // forecast
  const forecastNames = Array.from({ length: months }, (_, i) => {
    const [startYear, startMonth] = startDate.split("-").map(Number);
    return getDate(startYear, startMonth + i);
  });

  const monthsNames = [...bfNames, ...forecastNames];
  return monthsNames;
};
// result: "Dec 21", "Dec 22", "Dec 23", "Jan 24", "Feb 24", "Mar 24"

const getMonthsArray = (months, priorLength) => {
  // b/f
  const bfArray = Array.from({ length: priorLength }, () => "b/f");

  // forecast
  const forecastArray = Array.from({ length: months }, (_, i) => (i + 1).toString());

  const monthsArray = [...bfArray, ...forecastArray];
  return monthsArray;
};
// result: "b/f", "b/f", "b/f", "1", "2", "3"

const getMonthsNumbers = (months, priorYear) => {
  // b/f
  const bfNumbers = priorYear.headers[1].slice(1, -1).map(String);

  // forecast
  const forecastNumbers = Array.from({ length: months }, () => "1");

  const monthsNumbers = [...bfNumbers, ...forecastNumbers];
  return monthsNumbers;
};
// result: "12", "12", "12", "1", "1", "1"

const getTotalPeriod = (startDate, periodEnd, months, priorLength) => {
  const startMonth = parseInt(startDate.split("-")[1]);

  let remaining = months + priorLength;
  let totalPeriod = 0;

  // priorLength
  totalPeriod += priorLength;
  remaining -= priorLength;

  // initial
  if (remaining > 0) {
    totalPeriod += 1;
    if (periodEnd >= startMonth) {
      remaining -= periodEnd - startMonth + 1;
    } else {
      remaining -= periodEnd + 12 - startMonth + 1;
    }
  }

  totalPeriod += Math.ceil(remaining / 12);
  remaining -= remaining;

  return totalPeriod;
};

const getMonthsNames2 = (monthsDetails) => {
  const { startDate, periodEnd, priorYear, priorLength, totalMonths } = monthsDetails;
  let startYear = parseInt(startDate.split("-")[0]);
  const startMonth = parseInt(startDate.split("-")[1]);

  let monthsNames = [];
  let remaining = totalMonths;

  // b/f
  const dateStrings = priorYear.headers[0].slice(1, -1);
  const bfNames = dateStrings.map((dateString) => formatDate(dateString));
  monthsNames.push(...bfNames);
  remaining -= priorLength;

  // forecast - first
  if (remaining > 0) {
    let diff = 0;
    if (periodEnd >= startMonth) {
      diff = periodEnd - startMonth + 1;
    } else {
      diff = periodEnd + 12 - startMonth + 1;
    }

    if (remaining > diff) {
      if (periodEnd >= startMonth) {
        monthsNames.push(getDate(startYear, periodEnd));
      } else {
        startYear += 1;
        monthsNames.push(getDate(startYear, periodEnd));
      }
      startYear += 1;
      remaining -= diff;
    } else {
      monthsNames.push(getDate(startYear, startMonth + remaining - 1));
      remaining -= remaining;
    }
  }

  // forecast - middle
  while (remaining >= 12) {
    monthsNames.push(getDate(startYear, periodEnd));
    startYear += 1;
    remaining -= 12;
  }

  // forecast - last
  if (remaining > 0) {
    monthsNames.push(getDate(startYear, periodEnd + remaining));
    remaining -= remaining;
  }

  return monthsNames;
};
// result: "Dec 21", "Dec 22", "Dec 23", "Dec 24", "Dec 24", "Jun 24"

const getMonthsArray2 = (monthsDetails) => {
  // b/f
  const { priorLength, totalPeriod } = monthsDetails;
  const bfArray = Array.from({ length: priorLength }, () => "b/f");

  // forecast
  const forecastArray = Array.from({ length: totalPeriod - priorLength }, (_, i) => (i + 1).toString());

  const monthsArray = [...bfArray, ...forecastArray];
  return monthsArray;
};
// result: "b/f", "b/f", "b/f", "1", "2", "3"

const getMonthsNumbers2 = (monthsDetails) => {
  const { startDate, periodEnd, priorYear, priorLength, totalMonths } = monthsDetails;
  const startMonth = parseInt(startDate.split("-")[1]);

  let monthsNumbers = [];
  let remaining = totalMonths;

  // b/f
  const bfNumbers = priorYear.headers[1].slice(1, -1).map(String);
  monthsNumbers.push(...bfNumbers);
  remaining -= priorLength;

  // forecast - first
  if (remaining > 0) {
    let diff = 0;
    if (periodEnd >= startMonth) {
      diff = periodEnd - startMonth + 1;
    } else {
      diff = periodEnd + 12 - startMonth + 1;
    }

    if (remaining > diff) {
      monthsNumbers.push(diff.toString());
      remaining -= diff;
    } else {
      monthsNumbers.push(remaining.toString());
      remaining -= remaining;
    }
  }

  // forecast - middle
  while (remaining >= 12) {
    monthsNumbers.push("12");
    remaining -= 12;
  }

  // forecast - last
  if (remaining > 0) {
    monthsNumbers.push(remaining.toString());
    remaining -= remaining;
  }

  return monthsNumbers;
};
// result: "12", "12", "12", "6", "12", "6"
