import { useState } from "react";
import styles from "./styles.module.css";
import TutoVideo from "./components/TutoVideo";
import TutoList from "./components/TutoList";

const Tutorials = () => {
  const [isTutoVideoOpen, setTutoVideoOpen] = useState(false);
  const [isTutoListOpen, setTutoListOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const toggleTutoVideo = (slideIndex = 0) => {
    setTutoVideoOpen(!isTutoVideoOpen);
    setCurrentSlide(slideIndex);
    setTutoListOpen(false);
  };

  const toggleTutoList = () => {
    setTutoListOpen((prev) => !prev || isTutoVideoOpen);
    setTutoVideoOpen(false);
  };

  return (
    <div
      //
      style={{ height: "var(--tutorial)" }}
      className="shrink-0 bg-orange-500 rounded-2xl"
    >
      {isTutoVideoOpen && <TutoVideo toggleTutoVideo={toggleTutoVideo} currentSlide={currentSlide} />}
      {isTutoListOpen && <TutoList toggleTutoList={toggleTutoList} toggleTutoVideo={toggleTutoVideo} />}

      <div className={styles.frame}>
        <div className={styles.textFrame}>
          <h1>Get Ready To explore your financial landscape</h1>
          <p>Let's use this section to track key metrics at a glance and click in any metric for detailed insights.</p>
        </div>
        <div className={styles.btnFrame}>
          <button onClick={toggleTutoList} className={styles.tutoBtn}>
            Explore Tutorials
            <span>&#8250;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
