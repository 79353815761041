import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useStore } from "../../store";
import DeleteModal from "./DeleteModal";
import { EditIcon } from "./../svg/Icons";
import { DeleteIcon } from "./../svg/Icons";

const Table = ({ tab, setPage }) => {
  const navigate = useNavigate();
  const [modalDelete, setModalDelete] = useState(false);

  const contexts = useStore((state) => state.contexts);
  const { setContextId, deleteContext } = useStore.getState();

  const handleNavigateToFlow = (contextId) => {
    setContextId(contextId);
    navigate("/modeling/flow");
  };

  const handleNavigateToEdit = (context) => {
    setContextId(context.id);
    if (context.data.type === "company") {
      setPage("editCompany");
    }
    if (context.data.type === "campaign") {
      setPage("editCampaign");
    }
  };

  const handleDelete = (contextId) => {
    deleteContext(contextId);
    setModalDelete(false);
  };

  const handleOpenModal = () => {
    setModalDelete(true);
  };

  const handleCloseModal = () => {
    setModalDelete(false);
  };

  return (
    <table className="w-full text-left table-auto border-collapse border border-gray-300 rounded-lg overflow-hidden">
      <thead className="bg-gray-100 border-gray-100">
        <tr>
          <th className="px-4 py-2 font-medium border-gray-300 border-b border-r">Name</th>
          <th className="px-4 py-2 font-medium border-gray-300 border-b border-r">xxx</th>
          <th className="px-4 py-2 font-medium border-gray-300 border-b border-r">xxx</th>
          <th className="px-4 py-2 font-medium border-gray-300 border-b border-r">xxx</th>
          <th className="px-4 py-2 font-medium border-gray-300 border-b border-r">Action</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 border-gray-100">
        {contexts.map((context) => {
          if (context.data.type === tab) {
            return (
              <tr className="bg-gray-50" key={context.id}>
                <td className="px-4 py-3 border-gray-300 border-b border-r">
                  <button
                    className="text-secondary font-semibold hover:underline"
                    onClick={() => handleNavigateToFlow(context.id)}
                  >
                    {context.data.name}
                  </button>
                </td>
                <td className="px-4 py-3 border-gray-300 border-b border-r">{context.data.companyType}</td>
                <td className="px-4 py-3 border-gray-300 border-b border-r"></td>
                <td className="px-4 py-3 border-gray-300 border-b border-r"></td>
                <td className="px-4 py-3 flex items-center space-x-2 border-gray-300">
                  <button onClick={() => handleNavigateToEdit(context)}>
                    <EditIcon />
                  </button>
                  <button onClick={handleOpenModal}>
                    <DeleteIcon />
                  </button>
                  <DeleteModal
                    open={modalDelete}
                    onClose={handleCloseModal}
                    onConfirm={() => handleDelete(context.id)}
                    name={context.data.name}
                  />
                </td>
              </tr>
            );
          }
          return null;
        })}
      </tbody>
    </table>
  );
};

export default Table;
