import { create } from "zustand";
import { nanoid } from "nanoid";
import { applyNodeChanges, applyEdgeChanges } from "@xyflow/react";
import cloneDeep from "lodash/cloneDeep";
import { topologicalSortNodes } from "./utilsFunctions/topologicalSortNodes";

import { updatePriorYears } from "./update/updatePriorYears";
import { updateAssumptions } from "./update/updateAssumptions";
import { updateFlow } from "./update/updateFlow";
import { updateStatements } from "./update/updateStatements";
import { updateStatementsAnnual } from "./update/updateStatementsAnnual";
import { updateActual } from "./update/updateActual";
import { updateDifferences } from "./update/updateDifferences";
import { updateValuation } from "./update/updateValuation";
import { updateDashboard } from "./update/updateDashboard";
import { updateReport } from "./update/updateReport";

import emptyExample from "./samples/emptyExample.json";
import filledExample from "./samples/filledExample.json";

const dataItems = {
  contContext: {
    general: { description: "Context" },
  },

  contCapex: {
    general: { description: "CAPEX" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    individuals: [],
    outputs: [
      { id: "default_capex_cost", impact: "", description: "Cost", bfLink: null, long: [] },
      { id: "default_capex_accDep", impact: "", description: "Accumulated Depreciation", bfLink: null, long: [] },
      { id: "default_capex_capexcf", impact: "SOFP", description: "CAPEX c/f", bfLink: null, long: [] },
      { id: "default_capex_depExp", impact: "SOPL", description: "Depreciation Expense", bfLink: null, long: [] },
      { id: "default_capex_addBack", impact: "SOCF", description: "Add back depreciation", bfLink: null, long: [] },
      { id: "default_capex_purchase", impact: "SOCF", description: "Purchase of CAPEX", bfLink: null, long: [] },
    ],
  },
  contLoan: {
    general: { description: "Loan" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    individuals: [],
    outputs: [
      { id: "default_loan_loancf", impact: "SOFP", description: "Loan c/f", bfLink: null, long: [] },
      { id: "default_loan_intExp", impact: "SOPL", description: "Interest Expense", bfLink: null, long: [] },
      { id: "default_loan_addBack", impact: "SOCF", description: "Add back interest", bfLink: null, long: [] },
      { id: "default_loan_raise", impact: "SOCF", description: "Raise of Loan", bfLink: null, long: [] },
      { id: "default_loan_repayment", impact: "SOCF", description: "Repayment of Loan", bfLink: null, long: [] },
    ],
  },
  contShareCap: {
    general: { description: "Share Capital" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_raised",
        description: "Capital raised",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_capitalbf",
        description: "Share capital b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_capitalcf",
            description: "Share capital c/f",
          },
        ],
      },
      {
        id: "default_formulas_capitalcf",
        description: "Share capital c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_capitalbf",
            description: "Share capital b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_raised",
            description: "Capital raised",
          },
        ],
      },
      {
        id: "default_formulas_raised",
        description: "Capital raised",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_raised",
            description: "Capital raised",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_capitalbf", impact: "SOFP", description: "Share capital b/f", long: [] },
      { id: "default_formulas_capitalcf", impact: "SOFP", description: "Share capital c/f", long: [] },
      { id: "default_formulas_raised", impact: "SOCF", description: "Capital raised", long: [] },
    ],
  },
  contRetained: {
    general: { description: "Retained Earnings" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_profit",
        description: "Profit / (Loss)",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_dividend",
        description: "Dividend paid",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_rebf",
        description: "Retained earnings b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_recf",
            description: "Retained earnings c/f",
          },
        ],
      },
      {
        id: "default_formulas_recf",
        description: "Retained earnings c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_rebf",
            description: "Retained earnings b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_profit",
            description: "Profit / (Loss)",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dividend",
            description: "Dividend paid",
          },
        ],
      },
      {
        id: "default_formulas_dividend",
        description: "Dividend paid",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dividend",
            description: "Dividend paid",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_rebf", impact: "SOFP", description: "Retained earnings b/f", long: [] },
      { id: "default_formulas_recf", impact: "SOFP", description: "Retained earnings c/f", long: [] },
      { id: "default_formulas_dividend", impact: "SOCF", description: "Dividend paid", long: [] },
    ],
  },
  contReceivables: {
    general: { description: "Receivables" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_revenue",
        description: "Revenue",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_dso",
        description: "DSO",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_receivablesbf",
        description: "Receivables b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_receivablescf",
            description: "Receivables c/f",
          },
        ],
      },
      {
        id: "default_formulas_receivablescf",
        description: "Receivables c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "last12MonthsOf",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_revenue",
            description: "Revenue",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dso",
            description: "DSO",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "365",
          },
        ],
      },
      {
        id: "default_formulas_change",
        description: "Change in receivables",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "(",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_receivablescf",
            description: "Receivables c/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_receivablesbf",
            description: "Receivables b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: ")",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_receivablesbf", impact: "SOFP", description: "Receivables b/f", long: [] },
      { id: "default_formulas_receivablescf", impact: "SOFP", description: "Receivables c/f", long: [] },
      { id: "default_formulas_change", impact: "SOCF", description: "Change in receivables", long: [] },
    ],
  },
  contPayables: {
    general: { description: "Payables" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_expenses",
        description: "Expenses",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_dpo",
        description: "DPO",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_payablesbf",
        description: "Payables b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_payablescf",
            description: "Payables c/f",
          },
        ],
      },
      {
        id: "default_formulas_payablescf",
        description: "Payables c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "last12MonthsOf",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_expenses",
            description: "Expenses",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dpo",
            description: "DPO",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "365",
          },
        ],
      },
      {
        id: "default_formulas_change",
        description: "Change in payables",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_payablescf",
            description: "Payables c/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_payablesbf",
            description: "Payables b/f",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_payablesbf", impact: "SOFP", description: "Payables b/f", long: [] },
      { id: "default_formulas_payablescf", impact: "SOFP", description: "Payables c/f", long: [] },
      { id: "default_formulas_change", impact: "SOCF", description: "Change in payables", long: [] },
    ],
  },
  contCash: {
    general: { description: "Cash" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_operating",
        description: "Operating",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_investing",
        description: "Investing",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_financing",
        description: "Financing",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_cashbf",
        description: "Cash b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_cashcf",
            description: "Cash c/f",
          },
        ],
      },
      {
        id: "default_formulas_movement",
        description: "Movement",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_operating",
            description: "Operating",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_investing",
            description: "Investing",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_financing",
            description: "Financing",
          },
        ],
      },
      {
        id: "default_formulas_cashcf",
        description: "Cash c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_cashbf",
            description: "Cash b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_movement",
            description: "Movement",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_cashbf", impact: "SOFP", description: "Cash b/f", long: [] },
      { id: "default_formulas_movement", impact: "", description: "Movement", long: [] },
      { id: "default_formulas_cashcf", impact: "SOFP", description: "Cash c/f", long: [] },
    ],
  },
  contSofp: {
    general: { description: "SOFP" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_assets",
        description: "Assets",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_equity",
        description: "Equity",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_liabilities",
        description: "Liabilities",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_check",
        description: "Check",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_assets",
            description: "Assets",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_equity",
            description: "Equity",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_liabilities",
            description: "Liabilities",
          },
        ],
      },
    ],
    outputs: [{ id: "default_formulas_check", impact: "", description: "Check", long: [] }],
  },

  contBlank: {
    general: { description: "Blank" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    outputs: [],
  },
  contMarketing: {
    general: { description: "Marketing" },
    core: { platform: "Facebook", output: "Leads", leadsCalcType: "Easy" },
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_budget",
        description: "Budget",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_cpl",
        description: "CPL",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_leads",
        description: "Leads",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_budget",
            description: "Budget",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_cpl",
            description: "CPL",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_leads",
        description: "Leads",
        impact: "",
        long: [],
      },
    ],
  },
  contSales: {
    general: { description: "Sales" },
    core: { channel: "Retail" },
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_leads",
        description: "Leads",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_convRate",
        description: "Conv Rate",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_sales",
        description: "Sales",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_leads",
            description: "Leads",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_convRate",
            description: "Conv Rate",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "100",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_sales",
        description: "Sales",
        impact: "",
        long: [],
      },
    ],
  },
  contRevenue: {
    general: { description: "Revenue" },
    core: { stream: "Recurring" },
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_sales",
        description: "Sales",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_price",
        description: "Price per unit",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_revenue",
        description: "Revenue",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_sales",
            description: "Sales",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_price",
            description: "Price",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_revenue",
        description: "Revenue",
        impact: "",
        long: [],
      },
    ],
  },
  contStaff: {
    general: { description: "Staff" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    individuals: [],
    outputs: [
      {
        id: "default_staff_staffCosts",
        description: "Staff costs",
        impact: "SOPL",
        bfLink: null,
        long: [],
      },
    ],
  },
  contDirectCosts: {
    general: { description: "Direct Costs" },
    priors: [],
    inputsLink: [],
    inputsSelf: [], // + children
    outputs: [
      {
        id: nanoid(),
        description: "Direct Costs Total",
        impact: "SOPL",
        long: [],
      },
    ], // level 1 + total
  },
  contIndirectCosts: {
    general: { description: "Indirect Costs" },
    priors: [],
    inputsLink: [],
    inputsSelf: [], // + children
    outputs: [
      {
        id: nanoid(),
        description: "Indirect Costs Total",
        impact: "SOPL",
        long: [],
      },
    ], // level 1 + total
  },
  contExpenses: {
    general: { description: "Expenses" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_directCosts",
        description: "Direct costs",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_indirectCosts",
        description: "Indirect costs",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_expenses",
        description: "Expenses",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_directCosts",
            description: "Direct costs",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_indirectCosts",
            description: "Indirect costs",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_expenses",
        description: "Expenses",
        impact: "SOPL",
        long: [],
      },
    ],
  },
  contProfit: {
    general: { description: "Profit / (Loss)" },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_revenue",
        description: "Revenue",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_expenses",
        description: "Expenses",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_intExp",
        description: "Interest expense",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_taxRate",
        description: "Tax Rate",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_pbit",
        description: "PBIT",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_revenue",
            description: "Sales",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_expenses",
            description: "Expenses",
          },
        ],
      },
      {
        id: "default_formulas_pbt",
        description: "PBT",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_pbit",
            description: "PBIT",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_intExp",
            description: "Interest expense",
          },
        ],
      },
      {
        id: "default_formulas_tax",
        description: "Tax Expense",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_pbt",
            description: "PBT",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_taxRate",
            description: "Tax Rate",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "100",
          },
        ],
      },
      {
        id: "default_formulas_profit",
        description: "Profit / (Loss)",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_pbt",
            description: "PBT",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_tax",
            description: "Tax Expense",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_profit",
        description: "Profit / (Loss)",
        impact: "SOPL",
        long: [],
      },
    ],
  },
};

export const useStore = create((set, get) => ({
  contexts: [],
  nodes: [],
  edges: [],
  view: "view1",
  contextId: null,

  // AUTH

  logout() {
    // console.log("logout");

    const initialState = {
      contexts: [],
      nodes: [],
      edges: [],
      view: "view1",
      contextId: "",
    };
    set(initialState);
  },

  // UPDATE REPORTS

  updateAll() {
    // console.log("updateAll");

    updatePriorYears();
    updateAssumptions();
    updateFlow();
    updateStatements();
    updateStatementsAnnual();
    updateActual("others");
    updateDifferences();
    updateValuation();
    updateDashboard();
    updateReport();
  },

  // CONTEXTS
  createContext(data) {
    // console.log("createContext");

    const contextId = nanoid();

    const newContext = {
      id: contextId,
      data: data,
    };
    const updatedContexts = [...get().contexts, newContext];
    set({ contexts: updatedContexts });

    get().createNode("contSofp", "sofp", contextId, 0, 0);
    get().createNode("contProfit", "profit", contextId, 300, 0);
    get().createNode("contCash", "cash", contextId, 600, 0);

    get().updateAll();
  },

  deleteContext(id) {
    // console.log("deleteContext");

    const updatedContexts = get().contexts.filter((context) => context.id !== id);
    const updatedNodes = get().nodes.filter((node) => node.contextId !== id);
    const updatedEdges = get().edges.filter((edge) => edge.contextId !== id);
    set({ contexts: updatedContexts });
    set({ nodes: updatedNodes });
    set({ edges: updatedEdges });

    get().updateAll();
  },

  editContext(id, data) {
    // console.log("editContext");

    const updatedContexts = get().contexts.map((context) => {
      if (context.id === id) {
        return { ...context, data: data };
      }
      return context;
    });
    set({ contexts: updatedContexts });

    get().updateAll();
  },

  // NODES & EDGES

  createNode(type, container, contextId, x, y) {
    // console.log("createNode");

    const onlyOnce = ["sofp", "profit", "cash"];
    if (onlyOnce.includes(container)) {
      const filteredNodes = get().nodes.filter((node) => node.contextId === contextId);
      const exists = filteredNodes.some((node) => node.container === container);
      if (exists) {
        return;
      }
    }

    const id = nanoid();
    const position = { x: x ? x : 0, y: y ? y : 0 };
    const data = cloneDeep(dataItems[type]);

    const newNode = {
      id: id,
      position: position,
      data: data,
      type: type, // contMarketing contSales ...
      container: container, // marketing sales ...
      contextId: contextId,
    };
    set({ nodes: [...get().nodes, newNode] });

    if (container === "sofp" || container === "profit" || container === "cash") {
      get().updateAll();
    } else {
      updateFlow();
    }
  },

  updateNode(id, data) {
    // console.log("updateNode");

    get().nodes.forEach((node, index) => {
      if (node.id === id) {
        const updatedNodes = [...get().nodes];
        updatedNodes[index] = { ...node, data: { ...node.data, ...data } };

        set({ nodes: updatedNodes });
      }
    });

    get().updateAll();
  },

  updateAllNodes(nodes) {
    // console.log("updateAllNodes");

    set({ nodes: nodes });

    get().updateAll();
  },

  onNodesChange(changes) {
    // console.log("onNodesChange");

    const oldNodes = get().nodes;
    set({ nodes: applyNodeChanges(changes, oldNodes) });

    get().updateAll();
  },

  onEdgesChange(changes) {
    // console.log("onEdgesChange");

    const oldEdges = get().edges;
    set({ edges: applyEdgeChanges(changes, oldEdges) });

    get().updateAll();
  },

  addEdge(data, contextId) {
    // console.log("addEdge");

    // check for existing edge to prevent duplication
    const { source, target, sourceHandle, targetHandle } = data;
    const existingEdge = get().edges.find(
      (edge) =>
        edge.source === source &&
        edge.target === target &&
        edge.sourceHandle === sourceHandle &&
        edge.targetHandle === targetHandle
    );
    if (existingEdge) {
      return;
    }

    const newEdge = {
      id: nanoid(6),
      contextId: contextId,
      source: source,
      target: target,
      sourceHandle: sourceHandle,
      targetHandle: targetHandle,
    };

    const updatedEdges = [...get().edges, newEdge];
    const nodes = get().nodes;

    // check for cycle in topological sort
    const response = topologicalSortNodes(nodes, updatedEdges);
    if (response.success) {
      set({ edges: [...get().edges, newEdge] });
      get().updateAll();
    }
    return response;
  },

  setEdges(newEdges) {
    // console.log("setEdges");

    set({ edges: newEdges });
  },

  deleteAll(contextId) {
    // console.log("deleteAll");

    let nodes = [];
    let edges = [];
    nodes = get().nodes.filter((node) => node.contextId !== contextId);
    edges = get().edges.filter((edge) => edge.contextId !== contextId);

    set({ nodes: nodes });
    set({ edges: edges });

    get().createNode("contSofp", "sofp", contextId, 0, 0);
    get().createNode("contProfit", "profit", contextId, 300, 0);
    get().createNode("contCash", "cash", contextId, 600, 0);

    get().updateAll();
  },

  // SAMPLE

  generateExample(contextId, type) {
    // console.log("saasGenerate");

    const originalNodes = get().nodes.filter((node) => node.contextId !== contextId);
    const originalEdges = get().edges.filter((edge) => edge.contextId !== contextId);

    const dataCopy = type === "empty" ? cloneDeep(emptyExample) : cloneDeep(filledExample);

    const idMapping = {};
    const newNodes = dataCopy.nodes.map((node) => {
      const newId = nanoid();
      idMapping[node.id] = newId;
      return { ...node, id: newId, contextId: contextId };
    });
    const newEdges = dataCopy.edges.map((edge) => {
      const newId = nanoid();
      return {
        ...edge,
        id: newId,
        contextId: contextId,
        source: idMapping[edge.source] || edge.source,
        target: idMapping[edge.target] || edge.target,
      };
    });

    const nodes = [...originalNodes, ...newNodes];
    const edges = [...originalEdges, ...newEdges];
    set({ nodes: nodes });
    set({ edges: edges });

    get().updateAll();
  },

  // OTHERS

  setContextId(contextId) {
    set({ contextId: contextId });
  },

  getNode(id) {
    const node = get().nodes.find((node) => node.id === id);
    if (node) {
      return node;
    }
    return null;
  },

  getContainer(id) {
    const node = get().nodes.find((node) => node.id === id);
    if (node) {
      return node.container;
    }
    return null;
  },
}));
