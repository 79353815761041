import { nonEditable, showZero, emptyTextCell, textCell, numberCell } from "../../statements/reactgrid/cells";
import { nanoid } from "nanoid";

import {
  ROW_HEIGHT_28,
  ROW_HEIGHT_34,
  ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../../statements/reactgrid/getRows";

const getBlankRow = ({ assumption }) => {
  const { months } = assumption;

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [...Array.from({ length: months * 4 + 1 }, () => nonEditable(emptyTextCell()))],
    },
  ];
};

const getHeaderRow = ({ assumption }) => {
  const { priorLength, monthsNames } = assumption;
  const className1 = "text-lg font-weight-500 border-none bg-header text-white";
  const className2 = "text-lg font-weight-500 border-none bg-header text-white justify-content-center";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell("monthsNames", className1)),
        ...monthsNames.reduce((acc, month, index) => {
          if (index >= priorLength) {
            return [
              ...acc,
              nonEditable(textCell(month, className2)),
              nonEditable(textCell(month, className2)),
              nonEditable(textCell(month, className2)),
              nonEditable(textCell(month, className2)),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell("", className1)),
        ...monthsNames.reduce((acc, month, index) => {
          if (index >= priorLength) {
            return [
              ...acc,
              nonEditable(textCell("Projection", className2)),
              nonEditable(textCell("Actual", className2)),
              nonEditable(textCell("Difference", className2)),
              nonEditable(textCell("Difference (%)", className2)),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];
};

const getSubheaderRow = ({ assumption, title }) => {
  const { months } = assumption;
  const className1 = "text-lg font-weight-600";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months * 4 }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

const getSubSubheaderRow = ({ assumption, title }) => {
  const { months } = assumption;
  const className1 = "text-md font-weight-600";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months * 4 }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

const getSubtotalRow = ({ assumption, title, long }) => {
  const { priorLength } = assumption;
  const className1 = "text-md font-weight-500 bg-subtotal border-none border-subtotal";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.statements.reduce((acc, _, index) => {
          if (index >= priorLength) {
            const className2 = long.differenceM[index] > 0 ? "text-green" : "text-red";
            const className3 = long.differenceP[index] > 0 ? "text-green" : "text-red";
            return [
              ...acc,
              nonEditable(showZero(numberCell(long.statements[index], className1))),
              nonEditable(showZero(numberCell(long.actual[index], className1))),
              nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${className2}`))),
              nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${className3}`))),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];

  return row;
};

const getTotalRow = ({ assumption, title, long }) => {
  const { priorLength } = assumption;
  const className1 = "text-lg font-weight-600 bg-total border-none border-total";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.statements.reduce((acc, _, index) => {
          if (index >= priorLength) {
            const className2 = long.differenceM[index] > 0 ? "text-green" : "text-red";
            const className3 = long.differenceP[index] > 0 ? "text-green" : "text-red";
            return [
              ...acc,
              nonEditable(showZero(numberCell(long.statements[index], className1))),
              nonEditable(showZero(numberCell(long.actual[index], className1))),
              nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${className2}`))),
              nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${className3}`))),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];

  return row;
};

const getCheckRow = ({ assumption, title, long }) => {
  const { priorLength } = assumption;
  const className1 = "text-md font-weight-400 border-none check";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.statements.reduce((acc, _, index) => {
          if (index >= priorLength) {
            const isCheck1 = long.statements[index] !== 0 ? "notCheck" : "";
            const isCheck2 = long.actual[index] !== 0 ? "notCheck" : "";
            const isCheck3 = long.differenceM[index] !== 0 ? "notCheck" : "";
            const isCheck4 = long.differenceP[index] !== 0 ? "notCheck" : "";
            return [
              ...acc,
              nonEditable(showZero(numberCell(long.statements[index], `${className1} ${isCheck1}`))),
              nonEditable(showZero(numberCell(long.actual[index], `${className1} ${isCheck2}`))),
              nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${isCheck3}`))),
              nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${isCheck4}`))),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];

  return row;
};

const getContentRow = ({ assumption, category, description, longs }) => {
  const { priorLength } = assumption;
  const className1 = "text-md font-weight-400 padding-left-2";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.description, className1)),
      ...long.statements.reduce((acc, _, index) => {
        if (index >= priorLength) {
          const className2 = long.differenceM[index] > 0 ? "text-green" : "text-red";
          const className3 = long.differenceP[index] > 0 ? "text-green" : "text-red";
          return [
            ...acc,
            nonEditable(showZero(numberCell(long.statements[index], className1))),
            showZero(numberCell(long.actual[index], className1)),
            nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${className2}`))),
            nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${className3}`))),
          ];
        } else {
          return acc;
        }
      }, []),
    ],
  }));

  return rows;
};

export const getRowsSOFP = ({ assumption, differences }) => {
  const longProperties = differences.sofp.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    // assetsLongs,
    equityLongs,
    // liabilitiesLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow({ assumption })];

  // ASSETS
  rows = [...rows, ...getSubheaderRow({ assumption, title: "ASSETS" })];

  rows = [
    ...rows,
    ...getSubSubheaderRow({ assumption, title: "Non-current assets" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "nonCurrentAssetsLongs",
      longs: nonCurrentAssetsLongs,
    }),
    ...getSubtotalRow({ assumption, title: "Non-current assets", long: totalNonCurrentAssetsLong }),
    ...getBlankRow({ assumption }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ assumption, title: "Current assets" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "currentAssetsLongs",
      longs: currentAssetsLongs,
    }),
    ...getSubtotalRow({ assumption, title: "Current assets", long: totalCurrentAssetsLong }),
  ];
  rows = [
    ...rows,
    ...getTotalRow({ assumption, title: "Total Assets", long: totalAssetsLong }),
    ...getBlankRow({ assumption }),
  ];

  // EQUITY
  rows = [
    ...rows,
    ...getSubheaderRow({ assumption, title: "EQUITY" }),
    ...getContentRow({ assumption, category: "sofp", description: "equityLongs", longs: equityLongs }),
    ...getTotalRow({ assumption, title: "Total Equity", long: totalEquityLong }),
    ...getBlankRow({ assumption }),
  ];

  // LIABILITIES
  rows = [...rows, ...getSubheaderRow({ assumption, title: "LIABILITIES" })];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ assumption, title: "Non-current liabilities" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "nonCurrentLiabilitiesLongs",
      longs: nonCurrentLiabilitiesLongs,
    }),
    ...getSubtotalRow({ assumption, title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong }),
    ...getBlankRow({ assumption }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ assumption, title: "Current liabilities" }),
    ...getContentRow({
      assumption,
      category: "sofp",
      description: "currentLiabilitiesLongs",
      longs: currentLiabilitiesLongs,
    }),
    ...getSubtotalRow({ assumption, title: "Current liabilities", long: totalCurrentLiabilitiesLong }),
  ];
  rows = [
    ...rows,
    ...getTotalRow({ assumption, title: "Total Liabilities", long: totalLiabilitiesLong }),
    ...getBlankRow({ assumption }),
  ];

  // OVERALL
  rows = [...rows, ...getCheckRow({ assumption, title: "Check", long: checkLong }), ...getBlankRow({ assumption })];

  return rows;
};

export const getRowsSOPL = ({ assumption, differences }) => {
  const longProperties = differences.sopl.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    // totalRevenueLong,
    // totalExpensesLong,
    // totalIntExpLong,
    // totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow({ assumption })];

  rows = [
    ...rows,
    ...getContentRow({ assumption, category: "sopl", description: "revenueLongs", longs: revenueLongs }),
  ];
  rows = [
    ...rows,
    ...getContentRow({ assumption, category: "sopl", description: "expensesLongs", longs: expensesLongs }),
  ];
  rows = [...rows, ...getSubtotalRow({ assumption, title: "PBIT", long: totalPbitLong })];
  rows = [...rows, ...getContentRow({ assumption, category: "sopl", description: "intExpLongs", longs: intExpLongs })];
  rows = [...rows, ...getSubtotalRow({ assumption, title: "PBT", long: totalPbtLong })];
  rows = [...rows, ...getContentRow({ assumption, category: "sopl", description: "taxLongs", longs: taxLongs })];
  rows = [
    ...rows,
    ...getTotalRow({ assumption, title: "Profit/(Loss)", long: totalProfitLong }),
    ...getBlankRow({ assumption }),
  ];

  return rows;
};

export const getRowsSOCF = ({ assumption, differences }) => {
  const longProperties = differences.socf.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow({ assumption })];

  // OPERATING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ assumption, title: "Cash flow from operating activities" }),
    ...getContentRow({ assumption, category: "socf", description: "operatingLongs", longs: operatingLongs }),
    ...getSubtotalRow({ assumption, title: "Cash flow from operating activities", long: totalOperatingLong }),
    ...getBlankRow({ assumption }),
  ];

  // INVESTING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ assumption, title: "Cash flow from investing activities" }),
    ...getContentRow({ assumption, category: "socf", description: "investingLongs", longs: investingLongs }),
    ...getSubtotalRow({ assumption, title: "Cash flow from investing activities", long: totalInvestingLong }),
    ...getBlankRow({ assumption }),
  ];

  // FINANCING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ assumption, title: "Cash flow from financing activities" }),
    ...getContentRow({ assumption, category: "socf", description: "financingLongs", longs: financingLongs }),
    ...getSubtotalRow({ assumption, title: "Cash flow from financing activities", long: totalFinancingLong }),
    ...getBlankRow({ assumption }),
  ];

  rows = [
    ...rows,
    ...getTotalRow({ assumption, title: "Total cash flow movement", long: totalLong }),
    ...getBlankRow({ assumption }),
  ];

  return rows;
};
