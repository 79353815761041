import { Routes, Route } from "react-router-dom";

import Main from "./mainPage/Main";
import Flow from "./Flow";

const FlowRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/flow" element={<Flow />} />
    </Routes>
  );
};

export default FlowRoutes;
