import FlowCSS from "../../../../styles/pages/Flow.module.css";
import { useStore } from "../../store";
import { useAssumptionsStore } from "../../../../stores/useAssumptionsStore";
import SideCircle from "../../components/SideCircle";
import { DisplayOutputs } from "../utils/NodeIO";
import { Handle } from "@xyflow/react";
import { selector } from "./ContNormalNode";

const ContCostsNode = (fromReactFlow) => {
  const { id, data } = fromReactFlow;
  const { node, container } = useStore(selector(id, data));
  if (!node) return;
  const assumption = useAssumptionsStore.getState().assumptions.find((item) => item.contextId === node.contextId);
  const { priorLength } = assumption;

  return (
    <div className={FlowCSS.node}>
      <Handle type="target" position="left" />

      <SideCircle container={container} />

      <>
        <div className={FlowCSS.container_header}>
          <h2>{data.general.description}</h2>
        </div>

        <DisplayOutputs outputs={data.outputs} priorLength={priorLength} />
      </>
    </div>
  );
};

export default ContCostsNode;
