import styles from "./../../styles.module.css";
const Slide = ({ svgIcon, title, description, videoUrl }) => (
  <div className={styles.slide}>
    <div className={styles.modalTextFrame}>
      <div className={styles.textLayout}>
        <div className={styles.pieChart}>{svgIcon}</div>
        <div className={styles.modalText}>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </div>
    </div>
    <div className={styles.modalVd}>
      <p>How it works?</p>
      <iframe
        className={styles.styleVd}
        src={videoUrl}
        title="Baymax! | Official Trailer 2 | Disney+"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  </div>
);

export default Slide;
