import { useState } from "react";
import Cross from "./../svg/Cross";
import styles from "./../styles.module.css";
import { Icon1, Icon2, Icon3, Icon4, Icon5, Icon6 } from "../svg/Icons";

import Slide from "./slides/Slide";

const slideData = [
  {
    title: "Summary Overview",
    description: "Get a quick update of your overall performance & track key metrics at a glance!",
    videoUrl: "https://www.youtube.com/embed/7xidOWnzSu4",
    svgIcon: <Icon1 />,
  },
  {
    title: "Expense Breakdown",
    description: "Tracking your spending, setting your budget goals, and exploring expenses categories!",
    videoUrl: "https://www.youtube.com/embed/hDZ7y8RP5HE",
    svgIcon: <Icon2 />,
  },
  {
    title: "Revenue Projection",
    description: "Adjusting our projections to estimate your future earnings based on past data!",
    videoUrl: "https://www.youtube.com/embed/jWM0ct-OLsM",
    svgIcon: <Icon3 />,
  },
  {
    title: "Funding Needs",
    description: "A well-rounded budget can help you prepare for unexpected expenses.",
    videoUrl: "https://www.youtube.com/embed/7xidOWnzSu4",
    svgIcon: <Icon4 />,
  },
  {
    title: "Cashflow Forecast",
    description: "Anticipating cash inflows and outflows & also ensure you maintain a positive cash flow",
    videoUrl: "https://www.youtube.com/embed/hDZ7y8RP5HE",
    svgIcon: <Icon5 />,
  },
  {
    title: "Funding Sources",
    description: "Considering diverse funding sources-grants,loans and investments",
    videoUrl: "https://www.youtube.com/embed/jWM0ct-OLsM",
    svgIcon: <Icon6 />,
  },
];

const TutoVideo = ({ toggleTutoVideo, currentSlide }) => {
  const [slide, setSlide] = useState(currentSlide);
  const totalSlides = slideData.length;

  const nextSlide = () => setSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  const prevSlide = () => setSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));

  return (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContent} ${styles.modalVideoWidth}`}>
        <div className={styles.modalHeader}>
          <p>Get to know our features</p>
          <button onClick={toggleTutoVideo} className={styles.closeBtn}>
            <Cross />
          </button>
        </div>
        <div className={styles.sliderContainer}>
          <Slide {...slideData[slide]} />

          {/* Next and Prev Buttons */}
          <button onClick={nextSlide} className={`${styles.arrowBtn} ${styles.rightArrow}`}>
            &#8250;
          </button>
          <button onClick={prevSlide} className={`${styles.arrowBtn} ${styles.leftArrow}`}>
            &#8249;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutoVideo;
