export const calculateContLoan = (nodes, node, edges, priorYear, assumption) => {
  const { startDate, priorLength, totalMonths } = assumption;

  // let priors = [...node.data.priors];
  let inputs = [...node.data.inputs];
  let assignments = [...node.data.assignments];
  let formulas = [...node.data.formulas];
  // let individuals = [...node.data.individuals];
  let outputs = [...node.data.outputs];

  // PRIORS
  let priors = [];
  priorYear.rows.forEach((row) => {
    if (row[row.length - 1] === node.container) {
      priors.push(row.slice(0, -1));
    }
  });

  // ASSIGNMENTS
  // 2. update startDate if empty
  assignments = updateStartDate(assignments, startDate);

  // INDIVIDUALS
  const individuals = calculateIndividuals(assignments, totalMonths, startDate, priorLength);

  // OUTPUTS
  outputs = updateToZero(outputs, totalMonths);
  outputs.forEach((output, index) => {
    individuals.forEach((individual) => {
      for (let i = 0; i < totalMonths; i++) {
        output.long[i] += individual.outputs[index].long[i];
      }
    });
  });
  // update bfLink
  outputs.forEach((output) => {
    if (output.bfLink !== null) {
      const exists = priors.find((prior) => prior[0] === output.bfLink);
      if (!exists) {
        output.bfLink = null;
      } else {
        for (let i = 0; i < priorLength; i++) {
          output.long[i] = exists[i + 1];
        }
      }
    }
  });

  return [priors, inputs, assignments, formulas, individuals, outputs];
};

const updateStartDate = (assignments, startDate) => {
  assignments.forEach((assignment) => {
    if (assignment.custom.start === "") {
      assignment.custom.start = startDate;
    }
  });

  return assignments;
};

const calculateIndividuals = (assignments, totalMonths, startDate, priorLength) => {
  let individuals = [];

  assignments.forEach((assignment) => {
    let individual = [];
    individual = calculateIndividual(totalMonths, assignment, startDate, priorLength);

    individuals.push(individual);
  });

  return individuals;
};

const calculateIndividual = (totalMonths, assignment, startDate, priorLength) => {
  const { amount, eir, repayment, custom } = assignment;
  const { start } = custom;
  const diff = calculateMonthDiff(startDate, start);
  const diffWithPrior = diff + priorLength;

  let carryingAmt = [];
  let intExp = [];
  let addBackInt = [];
  let raiseOfLoan = Array(totalMonths).fill(0);
  let repaymentOfLoan = [];
  raiseOfLoan[0] = Math.round(amount);

  let currentCarryingAmt = amount;
  for (let i = 0; i < totalMonths - diff; i++) {
    const currentIntExp = Math.round((currentCarryingAmt * eir) / 100 / 12);
    intExp.push(currentIntExp);
    addBackInt.push(currentIntExp);

    let toPay = 0;
    if (currentCarryingAmt + currentIntExp - repayment >= 0) {
      toPay = Math.round(repayment);
    } else {
      toPay = Math.round(currentCarryingAmt + currentIntExp);
    }
    repaymentOfLoan.push(-toPay);
    currentCarryingAmt = Math.round(currentCarryingAmt + currentIntExp - toPay);
    carryingAmt.push(currentCarryingAmt);
  }

  // feb
  if (diffWithPrior > 0) {
    carryingAmt = adjustArray(carryingAmt, diffWithPrior, totalMonths);
    intExp = adjustArray(intExp, diffWithPrior, totalMonths);
    addBackInt = adjustArray(addBackInt, diffWithPrior, totalMonths);
    raiseOfLoan = adjustArray(raiseOfLoan, diffWithPrior, totalMonths);
    repaymentOfLoan = adjustArray(repaymentOfLoan, diffWithPrior, totalMonths);
  }
  // dec
  if (diffWithPrior < 0) {
    carryingAmt = adjustArray(carryingAmt, diffWithPrior, totalMonths);
    intExp = adjustArray(intExp, diffWithPrior, totalMonths);
    addBackInt = adjustArray(addBackInt, diffWithPrior, totalMonths);
    raiseOfLoan[0] = 0;
    repaymentOfLoan = adjustArray(repaymentOfLoan, diffWithPrior, totalMonths);
  }

  const individual = {
    id: assignment.id,
    description: assignment.description,
    outputs: [
      { description: "Loan c/f", long: carryingAmt },
      { description: "Interest Expense", long: intExp },
      { description: "Add back interest", long: addBackInt },
      { description: "Raise of Loan", long: raiseOfLoan },
      { description: "Repayment of Loan", long: repaymentOfLoan },
    ],
  };

  return individual;
};

const calculateMonthDiff = (start, end) => {
  const [startYear, startMonth] = start.split("-").map(Number);
  const [endYear, endMonth] = end.split("-").map(Number);
  return (endYear - startYear) * 12 + (endMonth - startMonth);
};

const adjustArray = (arr, diff, totalMonths) => {
  if (diff > 0) {
    if (diff > totalMonths) {
      return Array(totalMonths).fill(0);
    } else {
      return Array(diff)
        .fill(0)
        .concat(arr.slice(0, totalMonths - diff));
    }
  } else {
    return arr.slice(-diff).concat().slice(0, totalMonths);
  }
};

const updateToZero = (outputs, totalMonths) => {
  outputs.forEach((output) => (output.long = Array(totalMonths).fill(0)));
  return outputs;
};
