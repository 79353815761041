import MascotResting from "./MascotResting";

const Loading = () => {
  return (
    <div
      style={{ height: "calc(100vh - var(--navbar) - 4rem" }}
      className="flex flex-col items-center justify-center bg-white m-8 p-8 rounded-2xl"
    >
      <MascotResting />
      <p className="text-4xl font-semibold text-secondary my-8">Loading</p>
    </div>
  );
};

export default Loading;
