import { create } from "zustand";
import cloneDeep from "lodash/cloneDeep";
import { useStore } from "../pages/flow/store";

export const useAssumptionsStore = create((set, get) => ({
  assumptions: [],

  logout() {
    set({ assumptions: [] });
  },

  updateAssumptions(contextId, key, value) {
    // console.log("updateAssumptions");

    const newAssumptions = cloneDeep(get().assumptions);
    newAssumptions.forEach((assumption) => {
      // months - check number valid
      if (key === "months") {
        if (isNaN(value) || !isFinite(value) || value <= 0) {
          value = 1;
        }
        if (value > 120) {
          value = 120;
        }
      }

      // startDate - update all nodes startDate as well
      if (key === "startDate") {
        const oldValue = assumption[key];

        const updateExpenses = (input, oldValue, newValue) => {
          if (input.children.length === 0) {
            if (input.custom.start === oldValue) {
              const updatedInput = { ...input, custom: { ...input.custom, start: newValue } };
              return updatedInput;
            }
            return input;
          } else {
            const updatedChildren = input.children.map((child) => updateExpenses(child, oldValue, newValue));
            return { ...input, children: updatedChildren };
          }
        };
        const updateNormal = (assignment, oldValue, newValue) => {
          if (assignment.custom.start === oldValue) {
            const updatedAssignment = { ...assignment, custom: { ...assignment.custom, start: newValue } };
            return updatedAssignment;
          }
          return assignment;
        };

        const updateNode = (node, oldValue, newValue) => {
          if (node.container === "directCosts" || node.container === "indirectCosts") {
            const inputsSelf = node.data.inputsSelf.map((input) => updateExpenses(input, oldValue, newValue));
            node = { ...node, data: { ...node.data, inputsSelf: inputsSelf } };
          } else {
            if (node.data.assignments) {
              const assignments = node.data.assignments.map((assignment) =>
                updateNormal(assignment, oldValue, newValue)
              );
              node = { ...node, data: { ...node.data, assignments: assignments } };
            }
          }
          return node;
        };

        const { nodes, updateAllNodes } = useStore.getState();
        const updatedNodes = nodes.map((node) => {
          if (node.contextId === contextId) {
            return updateNode(node, oldValue, value);
          }
          return node;
        });
        updateAllNodes(updatedNodes);
      }

      if (assumption.contextId === contextId) {
        assumption[key] = value;
      }
    });

    set({ assumptions: newAssumptions });
    const updateAll = useStore.getState().updateAll;
    updateAll();
  },
}));
