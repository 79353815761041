import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";

import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import Summary from "./Summary";
import AddCompany from "./AddCompany";
import AddCampaign from "./AddCampaign";
import EditCompany from "./EditCompany";
import EditCampaign from "./EditCampaign";

const Main = () => {
  const [page, setPage] = useState("summary");

  const navigate = useNavigate();
  const contextId = useStore((state) => state.contextId);

  useEffect(() => {
    if (page === "summary" && contextId) {
      navigate("/modeling/flow");
    }
  }, [page, contextId, navigate]);

  return (
    <div
      //
      style={{ height: "calc(100vh - var(--navbar) - 1rem)" }}
      className="flex flex-col mx-8 gap-2"
    >
      <Breadcrumb />
      <div className="flex-grow overflow-y-auto p-4 bg-white rounded-3xl">
        {page === "summary" && <Summary setPage={setPage} />}
        {page === "addCompany" && <AddCompany setPage={setPage} />}
        {page === "addCampaign" && <AddCampaign setPage={setPage} />}
        {page === "editCompany" && <EditCompany setPage={setPage} />}
        {page === "editCampaign" && <EditCampaign setPage={setPage} />}
      </div>
    </div>
  );
};

export default Main;
