import { useState } from "react";
import GeneralChart from "./Chart";
import InfoCircle from "./InfoCircle";

const RevenueProjection = ({ annual, xaxisLabels }) => {
  const [isHovered, setIsHovered] = useState(false);

  const totalRevenueLong = annual.sopl.find((item) => item.description === "totalRevenueLong").long;
  const totalProfitLong = annual.sopl.find((item) => item.description === "totalProfitLong").long;
  const netProfitMarginLong = totalProfitLong.map((value, index) =>
    Math.round((value / totalRevenueLong[index]) * 100)
  );

  const chartOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [0, 0, 4],
    },
    xaxis: {
      categories: xaxisLabels,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2],
    },
    yaxis: [
      {
        title: { text: "Revenue ($)" },
        labels: {
          formatter: (value) => {
            if (value === 0) return 0;
            return `${Math.round(value / 1000).toLocaleString()}k`;
          },
        },
      },
      {
        title: { text: "Net Profit ($)" },
        opposite: true,
        labels: {
          formatter: (value) => {
            if (value === 0) return 0;
            return `${Math.round(value / 1000).toLocaleString()}k`;
          },
        },
      },
      {
        title: { text: "Net Profit Margin (%)" },
        opposite: true,
        formatter: (value) => {
          return `${value}%`;
        },
      },
    ],
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };

  const seriesData = [
    {
      name: "Revenue",
      type: "column",
      data: totalRevenueLong,
    },
    {
      name: "Net Profit",
      type: "column",
      data: totalProfitLong,
    },
    {
      name: "Net Profit Margin",
      type: "line",
      data: netProfitMarginLong,
    },
  ];

  return (
    <>
      <div className="flex items-center gap-2">
        <svg
          className="text-primary h-8 w-auto"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 13.75C9.5 14.72 10.25 15.5 11.17 15.5H13.05C13.85 15.5 14.5 14.82 14.5 13.97C14.5 13.06 14.1 12.73 13.51 12.52L10.5 11.47C9.91 11.26 9.51001 10.94 9.51001 10.02C9.51001 9.17999 10.16 8.48999 10.96 8.48999H12.84C13.76 8.48999 14.51 9.26999 14.51 10.24"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12 7.5V16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M17 3V7H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M22 2L17 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <h3 className="text-lg font-semibold">Revenue Projection</h3>
        <div
          className="relative cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <InfoCircle className="text-gray-300 h-6 w-auto" />
          {isHovered && (
            <div className="absolute text-sm top-[-0.8rem] left-[1.8rem] bg-blue-950 text-white p-2 rounded-lg shadow-lg w-[20rem] z-10">
              Forecast your expected income with insights into market trends and sales strategies, setting the stage for
              future growth.
              <div className="absolute top-[1.5rem] left-[-1rem] transform -translate-y-1/2 border-[10px] border-transparent border-r-blue-900"></div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex items-start justify-evenly w-2/5 mt-4">
        <div className="flex flex-col items-center">
          <h4 className="text-gray-400">Revenue</h4>
          <p className="text-2xl font-semibold">+00%</p>
        </div>
        <div className="flex flex-col items-center">
          <h4 className="text-gray-400">Profit Margin</h4>
          <p className="text-2xl font-semibold">+00%</p>
        </div>
      </div> */}
      <GeneralChart options={chartOptions} series={seriesData} type="line" />
    </>
  );
};

export default RevenueProjection;
