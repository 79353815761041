import styles from "./../styles.module.css";
import Barchart from "../svg/BarChart";
const TutoListItem = ({ title, description, onClick, icon }) => (
  <div className={styles.modalListFrame} onClick={onClick}>
    {icon && (
      <div className={styles.barchartStyle}>
        <Barchart />
      </div>
    )}
    <div className={styles.modalListTexts}>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  </div>
);

export default TutoListItem;
