import { useState } from "react";
import GeneralChart from "./Chart";
import InfoCircle from "./InfoCircle";

const ExpenseBreakdown = ({ annual, xaxisLabels }) => {
  const [isHovered, setIsHovered] = useState(false);

  const totalExpensesLong = annual.sopl.find((item) => item.description === "totalExpensesLong").long;
  const totalExpenses = totalExpensesLong.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const chartOptions = {
    chart: {
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    labels: xaxisLabels,
    colors: ["#845ef7", "#12B5CB", "#FEC260", "#FFB74D"],
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
              formatter: () => `$ ${totalExpenses.toLocaleString()}`,
            },
            value: {
              show: true,
              formatter: (val) => `$ ${val.toLocaleString()}`,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => `$ ${val.toLocaleString()}`,
      },
    },
  };

  const seriesData = totalExpensesLong;

  return (
    <>
      <div className="flex items-center gap-2">
        <svg
          className="text-primary h-8 w-auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="24"
          width="24"
        >
          <path d="M12 2L2 7l10 5 10-5-10-5zm0 18v-8l10-5m-10 13l-10-5v-6l10 5m0-5l10-5v6l-10 5" />
        </svg>
        <h3 className="text-lg font-semibold">Expense Breakdown</h3>
        <div
          className="relative cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <InfoCircle className="text-gray-300 h-6 w-auto" />
          {isHovered && (
            <div className="absolute text-sm top-[2.0rem] left-[-4.0rem] bg-blue-950 text-white p-2 rounded-lg shadow-lg w-[10rem] z-10">
              Explore a detailed analysis of your costs, highlighting key areas of expenditure to ensure budget
              transparency and control.
              <div className="absolute top-[-0.5rem] left-[4.2rem] transform -translate-y-1/2 border-[10px] border-transparent border-b-blue-900"></div>
            </div>
          )}
        </div>
      </div>
      <GeneralChart options={chartOptions} series={seriesData} type="donut" />
      {/* <div className="flex flex-col items-center">
        <div className="legend mt-2">
          <p>32.8% | 12.5% | 32.8% | 32.8%</p>
        </div>
      </div> */}
    </>
  );
};

export default ExpenseBreakdown;
