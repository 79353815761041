import { useStore } from "../../store";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";
import { Tooltip } from "@mui/material";

const HeaderDelete = ({ contextId }) => {
  const deleteAll = useStore.getState().deleteAll;

  // save
  const { autoSave } = useSaveContext();
  const handleDelete = () => {
    deleteAll(contextId);
    autoSave();
  };

  return (
    <Tooltip title="Delete All">
      <svg
        className="h-8 w-auto cursor-pointer"
        onClick={handleDelete}
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" />
      </svg>
    </Tooltip>
  );
};

export default HeaderDelete;
