import { useState } from "react";
import { useStore } from "../store";

const AddCompany = ({ setPage }) => {
  const [companyType, setCompanyType] = useState("parent");

  const [formData, setFormData] = useState({
    type: "company",
    name: "",
    industry: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleAdd = () => {
    const createContext = useStore.getState().createContext;
    createContext(formData);
    setPage("summary");
  };

  const handleCancel = () => {
    setPage("summary");
  };

  return (
    <div className="flex flex-col h-full p-4">
      <h1 className="text-xl font-semibold mb-4">Add Company</h1>

      <div className="flex-grow flex flex-col gap-2">
        <h2 className="text-lg font-medium">General Information</h2>

        <div className="flex flex-col">
          <h3 className="text-secondary font-semibold">Company Type</h3>

          <div className="flex items-center gap-4">
            <input
              className="w-4 h-4"
              id="radio"
              type="radio"
              value="parent"
              checked={companyType === "parent"}
              onChange={() => setCompanyType("parent")}
            />
            <span className={`${companyType === "parent" && "text-secondary"}`}>Parent Company</span>
          </div>
          <div className="flex items-center gap-4">
            <input
              type="radio"
              id="companyType"
              className="w-4 h-4"
              value=""
              checked={companyType === "subsidiary"}
              onChange={() => setCompanyType("subsidiary")}
            />
            <span className={`${companyType === "subsidiary" && "text-secondary"}`}>Subsidiary</span>
          </div>
          {companyType === "subsidiary" && (
            <select className="w-96 border border-gray-300 rounded-md p-2">
              <option>example1</option>
              <option>example2</option>
              <option>example3</option>
              <option>example4</option>
              <option>example5</option>
            </select>
          )}
        </div>

        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <h3 className="font-semibold text-secondary">Company Name</h3>
            <input
              type="text"
              id="name"
              autoComplete="name"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">Company Industry</h3>
            <input
              type="text"
              id="industry"
              autoComplete="industry"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter industry"
              value={formData.industry}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">xxx</h3>
            <input
              type="text"
              id="xxx"
              autoComplete="xxx"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter xxx"
              value={formData.xxx}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">yyy</h3>
            <input
              type="text"
              id="yyy"
              autoComplete="yyy"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter yyy"
              value={formData.yyy}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <button
          className="px-8 py-2 border border-primaryStrong text-primaryStrong font-medium rounded-xl hover:bg-primaryLight"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="px-8 py-2 border bg-primaryStrong text-white font-medium rounded-xl hover:bg-primary"
          onClick={handleAdd}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddCompany;
