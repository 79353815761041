export const Icon1 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 32 32">
      <rect width="32" height="32" fill="none" />
      <path fill="#e68f3d" d="M21.25 8.375V28h6.5V8.375zM12.25 28h6.5V4.125h-6.5zm-9 0h6.5V12.625h-6.5z" />
    </svg>
  );
};

export const Icon2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5em" height="5em" viewBox="-2 -2 24 24">
      <rect x="-2" y="-2" width="24" height="24" fill="none" />
      <path
        fill="#5ba7e1"
        d="M19.95 11c-.501 5.053-4.765 9-9.95 9c-5.523 0-10-4.477-10-10C0 4.815 3.947.551 9 .05v2.012A8.001 8.001 0 0 0 10 18a8 8 0 0 0 7.938-7h2.013zm0-2h-2.012A8.004 8.004 0 0 0 11 2.062V.049A10 10 0 0 1 19.95 9"
      />
    </svg>
  );
};

export const Icon3 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="none" />
      <g fill="#5ba7e1">
        <path d="M15 13v1H1.5l-.5-.5V0h1v13z" />
        <path d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708z" />
      </g>
    </svg>
  );
};

export const Icon4 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5em" height="5em" viewBox="0 0 24 24">
      <rect width="24" height="24" fill="none" />
      <path
        fill="#5ba7e1"
        fillRule="evenodd"
        d="M5.5 18a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <rect width="3" height="7" x="6.5" y="11.5" fill="#5ba7e1" rx=".5" />
      <rect width="3" height="13" x="10.5" y="5.5" fill="#5ba7e1" rx=".5" />
      <rect width="3" height="10" x="14.5" y="8.5" fill="#5ba7e1" rx=".5" />
    </svg>
  );
};

export const Icon5 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="none" />
      <path
        fill="#5ba7e1"
        fillRule="evenodd"
        d="M1.5 14H15v-1H2V0H1v13.5zM3 11.5v-8l.5-.5h2l.5.5v8l-.5.5h-2zm2-.5V4H4v7zm6-9.5v10l.5.5h2l.5-.5v-10l-.5-.5h-2zm2 .5v9h-1V2zm-6 9.5v-6l.5-.5h2l.5.5v6l-.5.5h-2zm2-.5V6H8v5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Icon6 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 100 100">
      <rect width="100" height="100" fill="none" />
      <path
        fill="#5ba7e1"
        d="M86.304 40.985H13.696c-.836 0-1.513.677-1.513 1.513v15.127c0 .836.677 1.513 1.513 1.513h72.608c.836 0 1.513-.677 1.513-1.513V42.497c0-.835-.677-1.512-1.513-1.512M56.261 17.848v-.053H13.696c-.836 0-1.513.677-1.513 1.513v15.127c0 .836.677 1.513 1.513 1.513h42.565v-.053a1.51 1.51 0 0 0 1.135-1.459V19.307c0-.704-.483-1.29-1.135-1.459m9.622 46.205H13.696c-.836 0-1.513.677-1.513 1.513v15.127c0 .836.677 1.513 1.513 1.513h52.187c.836 0 1.513-.677 1.513-1.513V65.566c0-.836-.677-1.513-1.513-1.513"
      />
    </svg>
  );
};
