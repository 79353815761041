import {
  calulatePriors,
  updateStartDate,
  updateBfLinkAssign,
  deleteOldEdges,
  updateAssignLinked,
  fillLong,
  updateAssignCustom,
  updateBfLinkFormulas,
  updateFormulasParams,
  calculateOutputs,
} from "./utilsCalculateMarketing";

export const calculateContNormal = (nodes, node, edges, priorYear, assumption) => {
  const { startDate, months, priorLength, totalMonths } = assumption;

  // PRIORS
  const priors = calulatePriors(priorYear.rows, node.container);

  // ASSIGNMENTS
  let assignments = [];
  let tempAssign = [];
  tempAssign = leadsCalcTypeAssign(tempAssign, node);
  tempAssign = updateStartDate(tempAssign, startDate);
  tempAssign = updateBfLinkAssign(tempAssign, priors);
  let myEdges = edges.filter((edge) => edge.target === node.id);
  myEdges = deleteOldEdges(tempAssign, myEdges, nodes);
  tempAssign = updateAssignLinked(tempAssign, myEdges, nodes);
  tempAssign = fillLong(tempAssign, totalMonths);
  tempAssign = updateAssignCustom(tempAssign, myEdges, priors, assumption);
  assignments = [...tempAssign];

  // FORMULAS
  let formulas = [];
  let tempFormulas = [];
  tempFormulas = leadsCalcTypeFormulas(tempFormulas, node);
  tempFormulas = updateBfLinkFormulas(tempFormulas, priors);
  tempFormulas = updateFormulasParams(tempFormulas, assignments, totalMonths);
  formulas = [...tempFormulas];

  // OUTPUTS
  const outputs = calculateOutputs(formulas, months, priorLength, priors, priorYear);

  return [priors, assignments, formulas, outputs];
};

const leadsCalcTypeAssign = (tempAssign, node) => {
  const defaultAssign = node.data.assignments.filter((item) => item.type === "Default");
  const customAssign = node.data.assignments.filter((item) => item.type !== "Default");

  tempAssign.push(...defaultAssign);
  tempAssign.push(...customAssign);

  return tempAssign;
};

const leadsCalcTypeFormulas = (tempFormulas, node) => {
  const defaultFormula = node.data.formulas.filter((item) => item.type === "Default");
  const customFormula = node.data.formulas.filter((item) => item.type === "Custom");

  tempFormulas.push(...defaultFormula);
  tempFormulas.push(...customFormula);

  return tempFormulas;
};
