import { useStore } from "../store";
import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";
import { useStatementsStore } from "../../../stores/useStatementsStore";
import { useActualStore } from "../../../stores/useActualStore";
import { useDashboardStore } from "../../../stores/useDashboardStore";
import { calc, calcPerc } from "./utilsDashboard";

export const updateDashboard = () => {
  const contexts = useStore.getState().contexts;
  const assumptions = useAssumptionsStore.getState().assumptions;
  const statements = useStatementsStore.getState().statements;
  const actuals = useActualStore.getState().actual;
  const dashboards = useDashboardStore.getState().dashboard;

  // remove deleted context
  const contextIds = contexts.map((context) => context.id);
  for (let i = dashboards.length - 1; i >= 0; i--) {
    if (!contextIds.includes(dashboards[i].contextId)) {
      dashboards.splice(i, 1);
    }
  }

  // add new context
  contexts.forEach((context) => {
    const dashboardIndex = dashboards.findIndex((difference) => difference.contextId === context.id);
    if (dashboardIndex === -1) {
      dashboards.push({
        contextId: context.id,
        selected: [],
        layout: [],
      });
    }
  });

  assumptions.forEach((assumption, index) => {
    const statement = statements[index];
    const actual = actuals[index];
    const dashboard = dashboards[index];

    if (statement.sofp.length === 0 || statement.sopl.length === 0 || statement.socf.length === 0) {
      return;
    }

    const { totalMonths } = assumption;

    // get data from statements
    const longSOFP = statement.sofp.reduce((acc, item) => {
      acc[item.description] = item.long;
      return acc;
    }, {});
    const longSOPL = statement.sopl.reduce((acc, item) => {
      acc[item.description] = item.long;
      return acc;
    }, {});
    // const longSOCF = statement.socf.reduce((acc, item) => {
    //   acc[item.description] = item.long;
    //   return acc;
    // }, {});
    const {
      // assetsLongs,
      // equityLongs,
      // liabilitiesLongs,
      // nonCurrentAssetsLongs,
      // currentAssetsLongs,
      // nonCurrentLiabilitiesLongs,
      // currentLiabilitiesLongs,
      totalAssetsLong: totalA,
      // totalNonCurrentAssetsLong,
      totalCurrentAssetsLong: totalCa,
      totalEquityLong: totalE,
      totalLiabilitiesLong: totalL,
      // totalNonCurrentLiabilitiesLong,
      totalCurrentLiabilitiesLong: totalCl,
      // checkLong,
    } = longSOFP;
    const {
      // revenueLongs,
      // expensesLongs,
      totalRevenueLong,
      // totalExpensesLong,
      totalProfitLong,
    } = longSOPL;
    // const {
    //   operatingLongs,
    //   investingLongs,
    //   financingLongs,
    //   totalOperatingLong,
    //   totalInvestingLong,
    //   totalFinancingLong,
    //   totalLong,
    // } = longSOCF;

    //actual
    const actualLongSOFP = actual.sofp.reduce((acc, item) => {
      acc[item.description] = item.long;
      return acc;
    }, {});
    const actualLongSOPL = actual.sopl.reduce((acc, item) => {
      acc[item.description] = item.long;
      return acc;
    }, {});
    // const actualLongSOCF = actual.socf.reduce((acc, item) => {
    //   acc[item.description] = item.long;
    //   return acc;
    // }, {});
    const {
      // assetsLongs: actualAssetsLongs,
      // equityLongs: actualAquityLongs,
      // liabilitiesLongs: actualLiabilitiesLongs,
      // nonCurrentAssetsLongs: actualNonCurrentAssestLongs,
      // nonCurrentLiabilitiesLongs: actualNonCurrentLiabilitiesLongs,
      // currentLiabilitiesLongs: actualCurrentLiabilitiesLongs,
      totalAssetsLong: totalAActual,
      // totalNonCurrentAssetsLong: actualTotalNonCurrentAssetsLong,
      totalCurrentAssetsLong: totalCaActual,
      totalEquityLong: totalEActual,
      totalLiabilitiesLong: totalLActual,
      // totalNonCurrentLiabilitiesLong: actualTotalNonCurrentLiabilitiesLong,
      totalCurrentLiabilitiesLong: totalClActual,
      // checkLong: actualCheckLong,
    } = actualLongSOFP;
    const {
      // revenueLongs: actualRevenueLongs,
      // expensesLongs: actualExpensesLongs,
      totalRevenueLong: actualTotalRevenueLong,
      // totalExpensesLong: actualTotalExpensesLong,
      totalProfitLong: actualTotalProfitLong,
    } = actualLongSOPL;
    // const {
    //   operatingLongs: actualOperatingLongs,
    //   investingLongs: actualInvestingLongs,
    //   financingLongs: actualFinancingLongs,
    //   totalOperatingLong: actualTotalOperatingLong,
    //   totalInvestingLong: actualTotalInvestingLong,
    //   totalFinancingLong: actualTotalFinancingLong,
    //   totalLong: actualTotalLong,
    // } = actualLongSOCF;

    // calculate
    const currentRatioLong = calc(totalCa, "/", totalCl, totalMonths);
    const actualCurrentRatioLong = calc(totalCaActual, "/", totalClActual, totalMonths);

    const workingCapitalLong = calc(totalCa, "-", totalCl, totalMonths);
    const actualWorkingCapitalLong = calc(totalCaActual, "-", totalClActual, totalMonths);

    const salesToWorkingCapitalLong = calc(totalRevenueLong, "/", workingCapitalLong, totalMonths);
    const actualSalesToWorkingCapitalLong = calc(actualTotalRevenueLong, "/", actualWorkingCapitalLong, totalMonths);

    const netProfitMarginLong = calcPerc(totalProfitLong, "/", totalRevenueLong, totalMonths);
    const actualNetProfitMarginLong = calcPerc(actualTotalProfitLong, "/", actualTotalRevenueLong, totalMonths);

    const assetTurnoverLong = calc(totalRevenueLong, "/", totalA, totalMonths);
    const actualAssetTurnoverLong = calc(actualTotalRevenueLong, "/", totalAActual, totalMonths);

    const debtToEquityRatioLong = calc(totalL, "/", totalE, totalMonths);
    const actualDebtToEquityRatioLong = calc(totalLActual, "/", totalEActual, totalMonths);

    const allCalculations = [
      {
        title: "Current Ratio",
        yData1: currentRatioLong,
        yData2: actualCurrentRatioLong,
      },
      {
        title: "Working Capital",
        yData1: workingCapitalLong,
        yData2: actualWorkingCapitalLong,
      },
      {
        title: "Sales to Working Capital Ratio",
        yData1: salesToWorkingCapitalLong,
        yData2: actualSalesToWorkingCapitalLong,
      },
      {
        title: "Net Profit Margin",
        yData1: netProfitMarginLong,
        yData2: actualNetProfitMarginLong,
      },
      {
        title: "Asset Turnover",
        yData1: assetTurnoverLong,
        yData2: actualAssetTurnoverLong,
      },
      {
        title: "Debt to Equity Ratio",
        yData1: debtToEquityRatioLong,
        yData2: actualDebtToEquityRatioLong,
      },
    ];

    const MAX_WIDTH = 4;
    const BOX_WIDTH = 2;
    const BOX_HEIGHT = 2;

    let layoutData = [];
    const selectedTitles = new Set(dashboard.selected);
    let currentX = 0;
    let currentY = 0;

    const { selected, layout } = dashboard;

    selected.forEach((title) => {
      const calculation = allCalculations.find((item) => item.title === title);
      const existing = layout.find((item) => item.i === title);

      if (existing) {
        if (currentX + existing.w > MAX_WIDTH) {
          currentX = 0;
          currentY += BOX_HEIGHT;
        }
        layoutData.push({
          ...existing,
          data: calculation,
          x: currentX,
          y: currentY,
        });
        currentX += existing.w;
      }

      if (!existing) {
        if (currentX + BOX_WIDTH > MAX_WIDTH) {
          currentX = 0;
          currentY += BOX_HEIGHT;
        }
        layoutData.push({
          data: calculation,
          i: calculation.title,
          x: currentX,
          y: currentY,
          w: BOX_WIDTH,
          h: BOX_HEIGHT,
          maxH: BOX_HEIGHT,
          minH: BOX_HEIGHT,
          minW: BOX_WIDTH,
          maxW: MAX_WIDTH,
        });
        currentX += BOX_WIDTH;
      }
    });
    layoutData = layoutData.filter((layoutItem) => selectedTitles.has(layoutItem.i));

    dashboard.layout = layoutData;
  });
};
