import { create } from "zustand";

export const useGeneralStore = create((set, get) => ({
  subscription: null,
  monthly: true,

  logout() {
    const initialState = {
      subscription: null,
      monthly: true,
    };
    set(initialState);
  },

  setSubscription(data) {
    set({ subscription: data });
  },

  setMonthly(data) {
    set({ monthly: data });
  },
}));
