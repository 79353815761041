import { useState } from "react";
import GeneralChart from "./Chart";
import InfoCircle from "./InfoCircle";

const CashflowForecast = ({ annual, xaxisLabels }) => {
  const [isHovered, setIsHovered] = useState(false);

  const assetsLongs = annual.sofp.find((item) => item.description === "assetsLongs").long;
  const cashItem = assetsLongs.find((item) => item.description === "Cash c/f");
  if (!cashItem) {
    return;
  }
  const cashLong = cashItem.long;

  const defaultColors = Array(12).fill("#bdbdbd");

  const cashflowSeries = cashLong;

  const avgValue = cashflowSeries.reduce((a, b) => a + b, 0) / cashflowSeries.length;

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
        distributed: true,
        borderRadius: 4,
        borderRadiusApplication: "end",
      },
    },
    colors: defaultColors,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xaxisLabels,
      labels: {
        style: {
          colors: "#8e8da4",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return "$" + value.toLocaleString();
        },
        style: {
          colors: "#8e8da4",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => {
          return "$" + value.toLocaleString();
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    annotations: {
      yaxis: [
        {
          y: avgValue,
          borderColor: "#000",
          label: {
            style: {
              color: "#fff",
              background: "#000",
              fontSize: "12px",
              padding: {
                left: 5,
                right: 5,
                top: 2,
                bottom: 2,
              },
            },
            text: `Avg $${avgValue.toLocaleString()}`,
          },
        },
      ],
      points: [
        {
          x: xaxisLabels,
          y: cashflowSeries,
          seriesIndex: 0,
          label: {
            borderColor: "#845ef7",
            offsetY: -15,
            style: {
              color: "#fff",
              background: "#845ef7",
            },
            text: `$ 2000`,
          },
        },
      ],
    },
  };

  const seriesData = [
    {
      name: "Cashflow",
      data: cashflowSeries,
    },
  ];

  return (
    <>
      <div className="flex items-center gap-2">
        <svg
          className="text-primary h-8 w-auto"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.11 11.1501H7.46005C6.83005 11.1501 6.32007 11.6601 6.32007 12.2901V17.4101H10.11V11.1501V11.1501Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.7613 6.6001H11.2413C10.6113 6.6001 10.1013 7.11011 10.1013 7.74011V17.4001H13.8913V7.74011C13.8913 7.11011 13.3913 6.6001 12.7613 6.6001Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5482 12.8501H13.8982V17.4001H17.6882V13.9901C17.6782 13.3601 17.1682 12.8501 16.5482 12.8501Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h3 className="text-lg font-semibold">Cashflow Forecast</h3>
        <div
          className="relative cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <InfoCircle className="text-gray-300 h-6 w-auto" />
          {isHovered && (
            <div className="absolute text-sm top-[-0.8rem] left-[1.8rem] bg-blue-950 text-white p-2 rounded-lg shadow-lg w-[20rem] z-10">
              Visualize your inflows and outflows to maintain liquidity, helping you manage day-to-day operations
              effectively.
              <div className="absolute top-[1.5rem] left-[-1rem] transform -translate-y-1/2 border-[10px] border-transparent border-r-blue-900"></div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="text-left pl-2">
        <h3 className="text-2xl m-0">$00</h3>
        <p className="text-gray-500 text-sm m-0">
          <span className="text-green-600 text-xs rounded-full border-2 border-green-600 bg-lightgreen mr-1">00%</span>
          vs last year
        </p>
      </div> */}
      <GeneralChart options={chartOptions} series={seriesData} type="bar" />
    </>
  );
};

export default CashflowForecast;
