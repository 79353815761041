import { memo } from "react";
import { useStore } from "./store";

import ContNormalDetail from "./containerNodes/details/ContNormalDetail";
import ContMarketingDetail from "./containerNodes/details/ContMarketingDetail";
import ContStaffDetail from "./containerNodes/details/ContStaffDetail";
import ContCostsDetail from "./containerNodes/details/ContCostsDetail";
import ContCapexDetail from "./containerNodes/details/ContCapexDetail";
import ContLoanDetail from "./containerNodes/details/ContLoanDetail";

const RightBar = ({ id }) => {
  const initialNodes = useStore((state) => state.nodes);
  const selectedNode = initialNodes.find((node) => node.id === id);
  const show = selectedNode ? true : false;

  return (
    <div
      className={`flex absolute ${show ? "right-0" : "right-[-40%]"} w-[40%] h-full overflow-y-auto bg-white shadow-lg z-[1000] transition-right duration-300 ease-in-out`}
    >
      {selectedNode && selectedNode.type === "contBlank" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contMarketing" && <ContMarketingDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contSales" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contRevenue" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contStaff" && <ContStaffDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contDirectCosts" && <ContCostsDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contIndirectCosts" && <ContCostsDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contExpenses" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contProfit" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contCapex" && <ContCapexDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contLoan" && <ContLoanDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contReceivables" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contPayables" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contShareCap" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contRetained" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contCash" && <ContNormalDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contSofp" && <ContNormalDetail node={selectedNode} />}
    </div>
  );
};

export default memo(RightBar);
