import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useActualStore } from "../../stores/useActualStore";
import { selector } from "./SOFP";
import { useStore } from "../flow/store";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../statements/reactgrid/styles.css";
import { getColumns } from "./reactgrid/getColumns";
import { getRowsSOPL } from "./reactgrid/getRows";

const SOPL = ({ assumption, differences }) => {
  const contextId = useStore((state) => state.contextId);
  const { autoSave } = useSaveContext();
  const { updateData } = useActualStore(selector(contextId));

  const columns = getColumns({ assumption });
  const rows = getRowsSOPL({ assumption, differences });

  const handleChanges = (changes) => {
    updateData(changes);
    autoSave();
  };

  return (
    <div className={StatementsCSS.tableContainer}>
      <h1>Statement of Profit or Loss</h1>
      <div className="outer">
        <div className="reactgrid">
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges}
            stickyTopRows={2}
            stickyLeftColumns={1}
            enableRangeSelection
          />
        </div>
      </div>
    </div>
  );
};

export default SOPL;
