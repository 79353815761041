import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "./store";

import Header from "./components/Header/Header";
import FlowTab from "./components/FlowTab";
import View from "./View";

const Flow = () => {
  const navigate = useNavigate();
  const contextId = useStore((state) => state.contextId);
  const [tab, setTab] = useState("Build");

  useEffect(() => {
    if (!contextId) {
      navigate("/modeling");
    }
  }, [contextId, navigate]);

  if (!contextId) {
    return;
  }

  return (
    <div
      //
      style={{ height: "calc(100vh - var(--navbar)" }}
      className="flex flex-col"
    >
      <div className="bg-white border-b">
        <Header contextId={contextId} />
      </div>
      <div className="bg-white border-b">
        <FlowTab tab={tab} setTab={setTab} />
      </div>
      {tab === "Build" && (
        <div className="bg-white flex-grow overflow-y-auto">
          <View contextId={contextId} />
        </div>
      )}
    </div>
  );
};

export default Flow;
