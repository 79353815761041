import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import { useAssumptionsStore } from "../../../../stores/useAssumptionsStore";
import { PriorsBox, AssignmentsBox, FormulasBox, OutputsBoxNormal } from "../utils/RightBarBox";
import { nodeTypesDesc } from "../../constants";
import { SelectCustom } from "../utils/Select";
import { nanoid } from "nanoid";

export const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setCore: (key, value) => {
    store.updateNode(id, { core: { ...data.core, [key]: value } });
  },

  setAssignsCustom: (index, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "value" && updated[index].custom.value === updated[index].custom.max) {
      updated[index].custom = { ...updated[index].custom, value: value, max: value };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index].custom = { ...updated[index].custom, [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setAssigns: (index, key, value) => {
    const updated = [...data.assignments];

    if (key === "type") value === "Default" ? (value = "Custom") : (value = "Default");
    if (key === "linkCustom") value === "Link" ? (value = "Custom") : (value = "Link");

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "value" && updated[index].value === updated[index].max) {
      updated[index] = { ...updated[index], value: value, max: value };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  addAssign: () => {
    store.updateNode(id, {
      assignments: [
        ...data.assignments,
        {
          id: nanoid(),
          description: "",
          type: "Custom",
          bfLink: null,
          linkCustom: "Link",
          custom: {
            value: 0,
            min: 0,
            max: 0,
            change: 0,
            start: "",
          },
          long: [],
          longCustom: [],
        },
      ],
    });
  },
  deleteAssign: (index) => {
    const updated = [...data.assignments];
    updated.splice(index, 1);
    store.updateNode(id, { assignments: updated });
  },
  setCustomLong: (index, colIndex, value) => {
    const updated = [...data.assignments];

    if (isNaN(value) || !isFinite(value)) {
      value = null;
    }

    if (value === null) {
      updated[index].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
      return;
    }

    if (updated[index].long[colIndex] !== value) {
      updated[index].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
    }
  },
  resetCustomLong: (index) => {
    const updated = [...data.assignments];

    updated[index].longCustom = updated[index].longCustom.map(() => null);
    store.updateNode(id, { assignments: updated });
  },

  setFormulas: (index, key, value) => {
    const updated = [...data.formulas];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { formulas: updated });
  },
  addFormula: () => {
    store.updateNode(id, {
      formulas: [
        ...data.formulas,
        {
          id: nanoid(),
          description: "",
          type: "Custom",
          calcType: "Basic",
          bfLink: null,
          impact: "",
          params: [],
        },
      ],
    });
  },
  deleteFormula: (index) => {
    const updated = [...data.formulas];
    updated.splice(index, 1);
    store.updateNode(id, { formulas: updated });
  },

  addFormulasParam: (index, info) => {
    const updated = [...data.formulas];
    updated[index].params.push(info);
    store.updateNode(id, { formulas: updated });
  },
  deleteFormulasParam: (parentIndex, index) => {
    const updated = [...data.formulas];
    updated[parentIndex].params.splice(index, 1);
    store.updateNode(id, { formulas: updated });
  },
  sortParamsIndex: (sortedParamsIndex, parentIndex) => {
    const updatedParams = sortedParamsIndex.map((id) =>
      data.formulas[parentIndex].params.find((param) => param.id === id)
    );
    const updatedFormulas = [...data.formulas];
    updatedFormulas[parentIndex] = { ...updatedFormulas[parentIndex], params: updatedParams };
    store.updateNode(id, { formulas: updatedFormulas });
  },
});

const ContMarketingDetail = ({ node }) => {
  const { id, data, contextId } = node;
  const {
    setGeneral,
    setCore,
    setAssignsCustom,
    setAssigns,
    addAssign,
    deleteAssign,
    setCustomLong,
    resetCustomLong,
    setFormulas,
    addFormula,
    deleteFormula,
    addFormulasParam,
    deleteFormulasParam,
    sortParamsIndex,
  } = useStore(selector(id, data));

  const priorYearss = usePriorYearsStore((state) => state.priorYears);
  const assumptionss = useAssumptionsStore((state) => state.assumptions);
  const priorYears = priorYearss.find((item) => item.contextId === contextId);
  const assumptions = assumptionss.find((item) => item.contextId === contextId);

  return (
    <div className={RightBarCSS.details}>
      <div>
        <div className={RightBarCSS.container_header}>
          <h2>{nodeTypesDesc[node.type]}</h2>
        </div>

        <>
          <h3>General</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.general}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Description</span>
                <input
                  type="text"
                  name="text"
                  className={RightBarCSS.inputTextLong}
                  value={data.general.description}
                  onChange={(e) => {
                    setGeneral("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>

        <>
          <h3>Core</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.core}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Platform</span>
                <SelectCustom
                  item={data.core}
                  keyQues={"platform"}
                  onChange={(e) => {
                    setCore("platform", e.target.value);
                  }}
                  choices={["Facebook", "Google", "Tiktok"]}
                />
              </div>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Output</span>
                <SelectCustom
                  item={data.core}
                  keyQues={"output"}
                  onChange={(e) => {
                    setCore("output", e.target.value);
                  }}
                  choices={["Leads", "Customers", "Revenue"]}
                />
              </div>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Leads Calculation Type</span>
                <SelectCustom
                  item={data.core}
                  keyQues={"leadsCalcType"}
                  onChange={(e) => {
                    setCore("leadsCalcType", e.target.value);
                  }}
                  choices={["Easy", "Medium", "Hard", "Custom"]}
                />
              </div>
            </div>
          </div>
        </>

        {data.priors.length > 0 && (
          <>
            <h3>Priors</h3>
            <div className={RightBarCSS.container}>
              <div className={RightBarCSS.priors}>
                <PriorsBox priors={data.priors} startDate={assumptions.startDate} priorYears={priorYears} />
              </div>
            </div>
          </>
        )}

        <>
          <h3>Inputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.assignments}>
              <AssignmentsBox
                assignments={data.assignments}
                setAssignsCustom={setAssignsCustom}
                setAssigns={setAssigns}
                addAssign={addAssign}
                deleteAssign={deleteAssign}
                setCustomLong={setCustomLong}
                resetCustomLong={resetCustomLong}
                assumptions={assumptions}
              />
            </div>
          </div>
        </>

        <>
          <h3>Formulas</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.formulas}>
              <FormulasBox
                formulas={data.formulas}
                setFormulas={setFormulas}
                sortParamsIndex={sortParamsIndex}
                addFormula={addFormula}
                deleteFormula={deleteFormula}
                addFormulasParam={addFormulasParam}
                deleteFormulasParam={deleteFormulasParam}
              />
            </div>
          </div>
        </>

        <>
          <h3>Outputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.outputs}>
              <OutputsBoxNormal outputs={data.outputs} assumptions={assumptions} />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ContMarketingDetail;
