export const generateLong = (item, start, priors = [], assumption) => {
  const { value, min, max, change } = item.custom;
  const { long, longCustom } = item;
  const { startDate, months, priorLength } = assumption;

  let currentValue = value;

  const [forecastStartYear, forecastStartMonth] = startDate.split("-").map(Number);
  const [startYear, startMonth] = start.split("-").map(Number);
  let monthDifference = (startYear - forecastStartYear) * 12 + (startMonth - forecastStartMonth);

  // handle negative month
  if (monthDifference < 0) {
    const catchUpMonths = Math.abs(monthDifference);
    for (let i = 0; i < catchUpMonths; i++) {
      currentValue = currentValue * (1 + change / 100);
    }
    monthDifference = 0;
  }

  // generateLong
  const totalMonths = months + priorLength;
  for (let i = 0; i < totalMonths; i++) {
    if (i < priorLength) {
      if (item.bfLink === null) {
        long[i] = 0;
      } else {
        const match = priors.find((prior) => prior[0] === item.bfLink);
        if (match) {
          long[i] = match.slice(1)[i];
        } else {
          long[i] = 0;
        }
        // long[i] = priors.find((prior) => prior[0] === item.bfLink).slice(1)[i];
      }
    } else {
      if (i - priorLength < monthDifference) {
        long[i] = 0;
      } else {
        long[i] = currentValue;
        currentValue = currentValue * (1 + change / 100);
      }
    }
  }

  // check min max
  for (let i = 0; i < totalMonths; i++) {
    if (i >= priorLength) {
      if (long[i] < min) {
        long[i] = min;
      } else if (long[i] > max) {
        long[i] = max;
      }
    }
  }

  // check custom long
  for (let i = 0; i < totalMonths; i++) {
    if (longCustom[i] !== null) {
      long[i] = longCustom[i];
    }
  }

  return long;
};

export const calculate = (operation) => {
  // Handle single number input
  if (operation.length === 1 && typeof operation[0] === "number") {
    return operation[0];
  }

  // Handle unary minus at the start
  if (operation.length >= 2 && operation[0] === "-" && operation[1] === "(") {
    operation.splice(0, 2, 0, "-", "("); // Modify to correctly handle unary minus at the start with parentheses
  } else if (operation.length === 2 && operation[0] === "-" && typeof operation[1] === "number") {
    return -operation[1];
  }

  if (operation.length < 3) {
    return 0;
  }

  // Function to evaluate expression within parentheses
  const evaluateParentheses = (subOperation) => {
    const result = calculate(subOperation);
    return result;
  };

  // Function to handle unary minus within parentheses
  const handleUnaryMinus = (operation) => {
    for (let i = 0; i < operation.length; i++) {
      if (operation[i] === "(" && operation[i + 1] === "-") {
        operation[i + 2] = -operation[i + 2];
        operation.splice(i + 1, 1);
      }
    }
  };

  // Find and evaluate expressions within parentheses
  let parenthesesIndex;
  while ((parenthesesIndex = operation.indexOf("(")) !== -1) {
    const closingIndex = operation.indexOf(")", parenthesesIndex);
    if (closingIndex === -1) {
      return 0;
    }
    const subOperation = operation.slice(parenthesesIndex + 1, closingIndex);
    const result = evaluateParentheses(subOperation);
    operation.splice(parenthesesIndex, closingIndex - parenthesesIndex + 1, result);
  }

  // Handle unary minus after parentheses evaluation
  handleUnaryMinus(operation);

  // Perform multiplication and division operations
  for (let i = 1; i < operation.length; i += 2) {
    if (operation[i] === "*" || operation[i] === "/") {
      const leftOperand = operation[i - 1];
      const rightOperand = operation[i + 1];
      if (typeof leftOperand !== "number" || typeof rightOperand !== "number") {
        return 0;
      }
      const result = operation[i] === "*" ? leftOperand * rightOperand : leftOperand / rightOperand;
      operation.splice(i - 1, 3, result);
      i -= 2;
    }
  }

  // Perform addition and subtraction operations
  let result = operation[0];
  for (let i = 1; i < operation.length; i += 2) {
    const operator = operation[i];
    const input = operation[i + 1];
    if (typeof input !== "number") {
      return 0;
    }
    switch (operator) {
      case "+":
        result += input;
        break;
      case "-":
        result -= input;
        break;
      default:
        return 0;
    }
  }

  if (isNaN(result) || !isFinite(result)) {
    return 0;
  }
  return result;
};
