import { useState } from "react";
import GeneralChart from "./Chart";
import InfoCircle from "./InfoCircle";

const FundingNeedsChart = () => {
  const [isHovered, setIsHovered] = useState(false);

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: ["#00cfe8", "#e3e3e3"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 4,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May"],
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$" + val.toLocaleString();
        },
      },
    },
  };

  const series = [
    {
      name: "Net income",
      data: [3000, 5000, 4000, 7000, 4000],
    },
    {
      name: "Gross income",
      data: [5000, 6000, 6000, 10000, 6000],
    },
  ];

  return (
    <>
      <div className="flex items-center gap-2">
        <svg
          className="text-primary h-8 w-auto"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 6L8 4M8 4L6 2M8 4H6C3.79086 4 2 5.79086 2 8M18 18L16 20M16 20L18 22M16 20H18C20.2091 20 22 18.2091 22 16M10.189 6.5C10.8551 3.91216 13.2042 2 16 2C19.3137 2 22 4.68629 22 8C22 10.7957 20.0879 13.1449 17.5001 13.811M14 16C14 19.3137 11.3137 22 8 22C4.68629 22 2 19.3137 2 16C2 12.6863 4.68629 10 8 10C11.3137 10 14 12.6863 14 16Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h3 className="text-lg font-semibold">Funding Needs</h3>
        <div
          className="relative cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <InfoCircle className="text-gray-300 h-6 w-auto" />
          {isHovered && (
            <div className="absolute text-sm top-[-0.8rem] left-[1.8rem] bg-blue-950 text-white p-2 rounded-lg shadow-lg w-[20rem] z-10">
              Identify and quantity the financial resources required to achieve your objectives, ensuring you have the
              capital to succeed.
              <div className="absolute top-[1.5rem] left-[-1rem] transform -translate-y-1/2 border-[10px] border-transparent border-r-blue-900"></div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex pl-6 justify-start">
        <p className="text-2xl font-medium m-0">+00%</p>
      </div> */}
      <GeneralChart options={options} series={series} type="bar" />
    </>
  );
};

export default FundingNeedsChart;
