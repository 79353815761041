import { useLocation } from "react-router-dom";

import Hub from "./svg/Hub";
import Solution from "./svg/Solution";
import Forecast from "./svg/Forecast";
import Arrow from "./svg/Arrow";

const styleGroup = "flex items-center gap-2";
const styleSpan = "text-sm font-medium text-gray-500";

const Breadcrumb = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const group = {
    dashboard: "Dashboard",
    modeling: "Modeling",
    statements: "Projection",
    actual: "Actual",
    compare: "Compare",
    prior: "Past Data",
    valuation: "Valuation",
    report: "Report",
  };

  return (
    <div
      //
      style={{ height: "var(--breadcrumb)" }}
      className="shrink-0 flex items-center gap-2"
    >
      <div className="flex items-center gap-2">
        <Hub />
        <span className="text-sm font-medium text-gray-500">Simplyfi Hub</span>
      </div>

      <Arrow />

      <div className={styleGroup}>
        <Solution />
        <span className={styleSpan}>Solution</span>
      </div>

      <Arrow />

      <div className={styleGroup}>
        <Forecast />
        <span className={styleSpan}>Forecast</span>
      </div>

      <Arrow />

      <div className={styleGroup}>
        <span className="text-sm font-semibold text-secondary">{group[path]}</span>
      </div>
    </div>
  );
};

export default Breadcrumb;
