import Cross from "./../svg/Cross";
import styles from "./../styles.module.css";
import TutoListItem from "./TutoListItem";

const listData = [
  {
    title: "Summary Overview",
    description: "Get a quick update of your overall performance & track key metrics at a glance!",
    slideIndex: 0,
    icon: true,
  },
  {
    title: "Expense Breakdown",
    description: "Tracking your spending, setting your budget goals, and exploring expenses categories!",
    slideIndex: 1,
  },
  {
    title: "Revenue Projection",
    description: "Adjusting our projections to estimate your future earnings based on past data!",
    slideIndex: 2,
  },
  {
    title: "Funding Needs",
    description: "A well-rounded budget can help you prepare for unexpected expenses.",
    slideIndex: 3,
  },
  {
    title: "Cashflow Forecast",
    description: "Anticipating cash inflows and outflows & also ensure you maintain a positive cash flow",
    slideIndex: 4,
  },
  {
    title: "Funding Sources",
    description: "Considering diverse funding sources-grants,loans and investments",
    slideIndex: 5,
  },
];

const TutoList = ({ toggleTutoList, toggleTutoVideo }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContent} ${styles.modalListWidth}`}>
        <div className={styles.tutoListCrossBtn}>
          <button onClick={toggleTutoList} className={styles.closeBtn}>
            <Cross />
          </button>
        </div>
        {listData.map(({ title, description, slideIndex, icon }) => (
          <TutoListItem
            key={title}
            title={title}
            description={description}
            onClick={() => toggleTutoVideo(slideIndex)}
            icon={icon}
          />
        ))}
      </div>
    </div>
  );
};

export default TutoList;
