import { Modal, Box, Button } from "@mui/material";
import { InputNumberNormal, InputNumberNested, InputNumberStaff } from "./InputNumber";
import { MonthPicker, MonthPickerNested } from "./MonthPicker";
import { TableEdit, TableEditNested, TableEditStaff, TableEditCapex } from "./Table";
import { SelectCustom } from "./Select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30rem",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "1rem",
};

export const ModalEditNumber = ({
  open,
  handleClose,
  item,
  index,
  setOutputs,
  setCustomLong,
  resetCustomLong,
  assumptions,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>Edit {item.description}</h2>
        <InputNumberNormal item={item.custom} index={index} ques="Value" keyQues="value" setOutputs={setOutputs} />
        <InputNumberNormal item={item.custom} index={index} ques="Min" keyQues="min" setOutputs={setOutputs} />
        <InputNumberNormal item={item.custom} index={index} ques="Max" keyQues="max" setOutputs={setOutputs} />
        <InputNumberNormal
          item={item.custom}
          index={index}
          ques="Change (%)"
          keyQues="change"
          setOutputs={setOutputs}
        />
        <MonthPicker item={item.custom} index={index} ques="Start" keyQues="start" setOutputs={setOutputs} />
        <Button
          onClick={() => resetCustomLong(index)}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Reset
        </Button>
        <TableEdit item={item} index={index} setCustomLong={setCustomLong} assumptions={assumptions} />
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export const ModalEditNumberNested = ({
  open,
  handleClose,
  item,
  depthNext,
  indicesNext,
  setOutputs,
  setCustomLong,
  resetCustomLong,
  assumptions,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>Edit {item.description}</h2>
        <InputNumberNested
          item={item.custom}
          depthNext={depthNext}
          indicesNext={indicesNext}
          ques="Value"
          keyQues="value"
          setOutputs={setOutputs}
        />
        <InputNumberNested
          item={item.custom}
          depthNext={depthNext}
          indicesNext={indicesNext}
          ques="Min"
          keyQues="min"
          setOutputs={setOutputs}
        />
        <InputNumberNested
          item={item.custom}
          depthNext={depthNext}
          indicesNext={indicesNext}
          ques="Max"
          keyQues="max"
          setOutputs={setOutputs}
        />
        <InputNumberNested
          item={item.custom}
          depthNext={depthNext}
          indicesNext={indicesNext}
          ques="Change (%)"
          keyQues="change"
          setOutputs={setOutputs}
        />
        <MonthPickerNested
          item={item.custom}
          depthNext={depthNext}
          indicesNext={indicesNext}
          ques="Start"
          keyQues="start"
          setOutputs={setOutputs}
        />
        <Button
          onClick={() => resetCustomLong(depthNext, indicesNext)}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Reset
        </Button>
        <TableEditNested
          item={item}
          depthNext={depthNext}
          indicesNext={indicesNext}
          setCustomLong={setCustomLong}
          assumptions={assumptions}
        />
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export const ModalEditStaff = ({
  open,
  handleClose,
  index,
  param,
  paramIndex,
  setOutputs,
  setCustomLong,
  resetCustomLong,
  assumptions,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>Edit {param.description}</h2>
        <InputNumberStaff
          item={param.custom}
          index={index}
          paramIndex={paramIndex}
          ques="Value"
          keyQues="value"
          setOutputs={setOutputs}
        />
        <InputNumberStaff
          item={param.custom}
          index={index}
          paramIndex={paramIndex}
          ques="Min"
          keyQues="min"
          setOutputs={setOutputs}
        />
        <InputNumberStaff
          item={param.custom}
          index={index}
          paramIndex={paramIndex}
          ques="Max"
          keyQues="max"
          setOutputs={setOutputs}
        />
        <InputNumberStaff
          item={param.custom}
          index={index}
          paramIndex={paramIndex}
          ques="Change (%)"
          keyQues="change"
          setOutputs={setOutputs}
        />
        <Button
          onClick={() => resetCustomLong(index, paramIndex)}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Reset
        </Button>
        <TableEditStaff
          item={param}
          index={index}
          paramIndex={paramIndex}
          setCustomLong={setCustomLong}
          assumptions={assumptions}
        />
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export const ModalEditFormulas = ({ open, handleClose, item, index, setOutputs }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>Edit {item.description}</h2>
        <Box>
          <span>Impact: </span>
          {item.type === "Default" && (
            <SelectCustom
              item={item}
              keyQues={"impact"}
              onChange={(e) => {
                setOutputs(index, "impact", e.target.value);
              }}
              choices={["", "SOFP", "SOPL", "SOCF"]}
              disabled
            />
          )}
          {item.type === "Custom" && (
            <SelectCustom
              item={item}
              keyQues={"impact"}
              onChange={(e) => {
                setOutputs(index, "impact", e.target.value);
              }}
              choices={["", "SOFP", "SOPL", "SOCF"]}
            />
          )}
        </Box>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export const ModalEditCapex = ({ open, handleClose, item, index, setCustomLong, resetCustomLong, assumptions }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>Edit {item.description}</h2>
        <Button
          onClick={() => resetCustomLong(index, "quantityMultiple")}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Reset
        </Button>
        <TableEditCapex item={item} index={index} setCustomLong={setCustomLong} assumptions={assumptions} />
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
