import Modal from "@mui/material/Modal";

const DeleteModal = ({ open, onClose, onConfirm, name }) => {
  return (
    <Modal className="flex items-center justify-center" open={open} onClose={onClose}>
      <div className="bg-white rounded-xl px-12 py-8 max-w-xl flex flex-col gap-8">
        <div className="flex justify-center items-center">
          <div className="bg-amber-50 p-3 rounded-full">
            <div className="bg-amber-100 p-2 rounded-full">
              <svg
                className="text-amber-600 h-10 w-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 5.99L19.53 19H4.47zM2.74 18c-.77 1.33.19 3 1.73 3h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0zM11 11v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1m0 5h2v2h-2z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-center font-medium text-lg">Delete: {name}</p>
          <p className="text-center text-sm text-gray-400 ">
            This action will erase all related information. Once deleted, data cannot be recovered. Are you sure you
            want to proceed?
          </p>
        </div>

        <div className="flex">
          <button className="font-medium text-primaryStrong h-10 w-full" onClick={onClose}>
            Cancel
          </button>
          <button className="font-medium text-white bg-primaryStrong rounded-xl h-10 w-full" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
