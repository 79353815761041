import FlowCSS from "../../../../styles/pages/Flow.module.css";
import { useStore } from "../../store";
import { useAssumptionsStore } from "../../../../stores/useAssumptionsStore";
import SideCircle from "../../components/SideCircle";
import { InputNode, DisplayOutputs } from "../utils/NodeIO";
import { selector } from "./ContNormalNode";

const ContStaffNode = (fromReactFlow) => {
  const { id, data } = fromReactFlow;
  const { node, container } = useStore(selector(id, data));
  if (!node) return;
  const assumption = useAssumptionsStore.getState().assumptions.find((item) => item.contextId === node.contextId);
  const { priorLength } = assumption;

  return (
    <div className={FlowCSS.node}>
      <SideCircle container={container} />

      <>
        <div className={FlowCSS.container_header}>
          <h2>{data.general.description}</h2>
        </div>

        <>
          <h3>Number of Staff</h3>
          <div className={FlowCSS.big}>
            {data.assignments.map((item, index) => (
              <InputNode
                key={index}
                name={item.description}
                value={item.params[0].long[priorLength]}
                handleId={item.params[0].id}
              />
            ))}
          </div>
        </>

        <DisplayOutputs outputs={data.outputs} priorLength={priorLength} />
      </>
    </div>
  );
};

export default ContStaffNode;
