import { useStore } from "../store";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";

import { topologicalSortNodes } from "../utilsFunctions/topologicalSortNodes";

import { calculateContMarketing } from "../utils/utilsCalculateMarketing";
import { calculateContNormal } from "../utils/utilsCalculateNormal";
import { calculateContDirectCosts } from "../utils/utilsCalculateExpenses";
import { calculateContCapex } from "../utils/utilsCalculateCapex";
import { calculateContLoan } from "../utils/utilsCalculateLoan";
import { calculateContStaff } from "../utils/utilsCalculateStaff";

// update
export const updateFlow = () => {
  const { nodes, edges } = useStore.getState();
  const priorYears = usePriorYearsStore.getState().priorYears;
  const assumptions = useAssumptionsStore.getState().assumptions;

  const sortedNodes = topologicalSortNodes(nodes, edges).nodes;

  sortedNodes.forEach((node) => {
    const priorYear = priorYears.find((priorYear) => priorYear.contextId === node.contextId);
    const assumption = assumptions.find((assumption) => assumption.contextId === node.contextId);
    if (node.type === "contMarketing") {
      const [priors, assignments, formulas, outputs] = calculateContMarketing(
        nodes,
        node,
        edges,
        priorYear,
        assumption
      );
      node.data.priors = priors;
      node.data.assignments = assignments;
      node.data.formulas = formulas;
      node.data.outputs = outputs;
    } else if (node.type === "contDirectCosts" || node.type === "contIndirectCosts") {
      const [inputsLink, inputsSelf, outputs] = calculateContDirectCosts(
        //
        nodes,
        node,
        edges,
        priorYear,
        assumption
      );
      node.data.inputsLink = inputsLink;
      node.data.inputsSelf = inputsSelf;
      node.data.outputs = outputs;
    } else if (node.type === "contCapex") {
      const [priors, inputs, assignments, formulas, individuals, outputs] = calculateContCapex(
        nodes,
        node,
        edges,
        priorYear,
        assumption
      );
      node.data.priors = priors;
      node.data.inputs = inputs;
      node.data.assignments = assignments;
      node.data.formulas = formulas;
      node.data.individuals = individuals;
      node.data.outputs = outputs;
    } else if (node.type === "contLoan") {
      const [priors, inputs, assignments, formulas, individuals, outputs] = calculateContLoan(
        nodes,
        node,
        edges,
        priorYear,
        assumption
      );
      node.data.priors = priors;
      node.data.inputs = inputs;
      node.data.assignments = assignments;
      node.data.formulas = formulas;
      node.data.individuals = individuals;
      node.data.outputs = outputs;
    } else if (node.type === "contStaff") {
      const [priors, inputs, assignments, formulas, individuals, outputs] = calculateContStaff(
        nodes,
        node,
        edges,
        priorYear,
        assumption
      );
      node.data.priors = priors;
      node.data.inputs = inputs;
      node.data.assignments = assignments;
      node.data.formulas = formulas;
      node.data.individuals = individuals;
      node.data.outputs = outputs;
    } else {
      const [priors, assignments, formulas, outputs] = calculateContNormal(
        //
        nodes,
        node,
        edges,
        priorYear,
        assumption
      );
      node.data.priors = priors;
      node.data.assignments = assignments;
      node.data.formulas = formulas;
      node.data.outputs = outputs;
    }
  });
};
