import { useSaveContext } from "../../saveAndRestore/useSaveContext";

const styleProgress = "flex items-center justify-center h-full w-28 rounded-xl";
const styleSaved = "text-green-500 border border-green-500";
const styleSaving = "text-red-500 border border-red-500";

const Save = () => {
  const { isSaved, onSave } = useSaveContext();

  return (
    <div className="flex h-10">
      {isSaved ? (
        <span className={`${styleProgress} ${styleSaved}`}>Saved</span>
      ) : (
        <span className={`${styleProgress} ${styleSaving}`}>Saving...</span>
      )}
      <button className="h-full w-24 rounded-xl bg-primaryStrong text-white font-semibold" onClick={onSave}>
        Save
      </button>
    </div>
  );
};

export default Save;
