import HeaderBack from "./HeaderBack";
import HeaderName from "./HeaderName";
import HeaderSettings from "./HeaderSettings";
import HeaderGenerateExample from "./HeaderGenerateExample";
import HeaderTemplate from "./HeaderTemplate";
import HeaderDelete from "./HeaderDelete";
import Save from "../../../../components/saveProgress/Save";

const Header = ({ contextId }) => {
  return (
    <div
      //
      style={{ height: "4rem" }}
      className="flex justify-between px-8"
    >
      <div className="flex h-full items-center gap-4">
        <HeaderBack />
        <HeaderName />
      </div>
      <div className="flex h-full items-center gap-4">
        <HeaderGenerateExample contextId={contextId} />
        <HeaderTemplate contextId={contextId} />
        <HeaderSettings contextId={contextId} />
        <HeaderDelete contextId={contextId} />
      </div>
      <div className="flex h-full items-center">
        <Save />
      </div>
    </div>
  );
};

export default Header;
